import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearch.module.css';

const IconSearch = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4872 3.53846C7.09639 3.53846 3.53846 7.09356 3.53846 11.4771C3.53846 15.8607 7.09639 19.4158 11.4872 19.4158C13.6777 19.4158 15.6599 18.5318 17.0983 17.1C18.5433 15.6617 19.4359 13.6742 19.4359 11.4771C19.4359 7.09356 15.878 3.53846 11.4872 3.53846ZM2 11.4771C2 6.24221 6.24839 2 11.4872 2C16.726 2 20.9744 6.24221 20.9744 11.4771C20.9744 13.8225 20.1207 15.9697 18.7083 17.624L21.7744 20.6865C22.075 20.9868 22.0752 21.4738 21.775 21.7744C21.4748 22.075 20.9877 22.0752 20.6872 21.775L17.6182 18.7096C15.965 20.1093 13.8242 20.9542 11.4872 20.9542C6.24839 20.9542 2 16.712 2 11.4771Z"
        fill="#4a4a4a"
      />
    </svg>
  );
};

IconSearch.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearch;
