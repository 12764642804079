import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes an order for a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL = 'transition/request-payment-with-paypal';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';

// These are offer transitions

export const TRANSITION_SEND_OFFER = 'transition/send-offer';
export const TRANSITION_ACCEPT_OFFER = 'transition/accept-offer';
export const TRANSITION_DECLINE_OFFER = 'transition/decline-offer';
export const TRANSITION_COUNTER_OFFER = 'transition/counter-offer';
export const TRANSITION_ACCEPT_AFTER_COUNTER_OFFER = 'transition/accept-after-counter-offer';
export const TRANSITION_DECLINE_AFTER_COUNTER_OFFER = 'transition/decline-after-counter-offer';

export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL =
  'transition/request-payment-after-enquiry-with-paypal';
export const TRANSITION_CUSTOM_REQUEST_PAYMENT_AFTER_ENQUIRY =
  'transition/custom-request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.

export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL = 'transition/confirm-payment-with-paypal';
export const TRANSITION_CUSTOM_CONFIRM_PAYMENT = 'transition/custom-confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.

export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
export const TRANSITION_EXPIRE_PAYMENT_WITH_PAYPAL = 'transition/expire-payment-with-paypal';
export const TRANSITION_CUSTOM_EXPIRE_PAYMENT = 'transition/custom-expire-payment';

// Provider can mark the product shipped/delivered
export const TRANSITION_MARK_DELIVERED = 'transition/mark-delivered';
export const TRANSITION_MARK_DELIVERED_WITH_PAYPAL = 'transition/mark-delivered-with-paypal';
export const TRANSITION_CUSTOM_MARK_DELIVERED = 'transition/custom-mark-delivered';

// Customer can mark the product received (e.g. picked up from provider)
export const TRANSITION_MARK_RECEIVED_FROM_PURCHASED = 'transition/mark-received-from-purchased';
export const TRANSITION_MARK_RECEIVED_FROM_PURCHASED_WITH_PAYPAL =
  'transition/mark-received-from-purchased-with-paypal';
export const TRANSITION_CUSTOM_MARK_RECEIVED_FROM_PURCHASED =
  'transition/custom-mark-received-from-purchased';

// Automatic cancellation happens if none marks the delivery happened

export const TRANSITION_AUTO_CANCEL = 'transition/auto-cancel';
export const TRANSITION_AUTO_CANCEL_WITH_PAYPAL = 'transition/auto-cancel-with-paypal';

// Operator can cancel the purchase before product has been marked as delivered / received
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_CUSTOM_CANCEL = 'transition/custom-cancel';
export const TRANSITION_CANCEL_WITH_PAYPAL = 'transition/cancel-with-paypal';
export const TRANSITION_REFUND_INITIATED = 'transition/refund-initiated';

// If provider has marked the product delivered (e.g. shipped),
// customer can then mark the product received
export const TRANSITION_MARK_RECEIVED = 'transition/mark-received';
export const TRANSITION_MARK_RECEIVED_WITH_PAYPAL = 'transition/mark-received-with-paypal';
export const TRANSITION_CUSTOM_MARK_RECEIVED = 'transition/custom-mark-received';

// If customer doesn't mark the product received manually, it can happen automatically
export const TRANSITION_AUTO_MARK_RECEIVED = 'transition/auto-mark-received';
export const TRANSITION_AUTO_MARK_RECEIVED_WITH_PAYPAL =
  'transition/auto-mark-received-with-paypal';
export const TRANSITION_CUSTOM_AUTO_MARK_RECEIVED = 'transition/custom-auto-mark-received';

// When provider has marked the product delivered, customer can dispute the transaction
export const TRANSITION_DISPUTE = 'transition/dispute';
export const TRANSITION_DISPUTE_WITH_PAYPAL = 'transition/dispute-with-paypal';
export const TRANSITION_CUSTOM_DISPUTE = 'transition/custom-dispute';

// If nothing is done to disputed transaction it ends up to Canceled state

export const TRANSITION_AUTO_CANCEL_FROM_DISPUTED = 'transition/auto-cancel-from-disputed';
export const TRANSITION_AUTO_CANCEL_FROM_DISPUTED_WITH_PAYPAL =
  'transition/auto-cancel-from-disputed-with-paypal';
export const TRANSITION_CUSTOM_AUTO_CANCEL_FROM_DISPUTED =
  'transition/custom-auto-cancel-from-disputed';

// Operator can cancel disputed transaction manually

export const TRANSITION_CANCEL_FROM_DISPUTED = 'transition/cancel-from-disputed';
export const TRANSITION_CANCEL_FROM_DISPUTED_WITH_PAYPAL =
  'transition/cancel-from-disputed-with-paypal';
export const TRANSITION_CUSTOM_CANCEL_FROM_DISPUTED = 'transition/custom-cancel-from-disputed';

// Operator can mark the disputed transaction as received
export const TRANSITION_MARK_RECEIVED_FROM_DISPUTED = 'transition/mark-received-from-disputed';
export const TRANSITION_MARK_RECEIVED_FROM_DISPUTED_WITH_PAYPAL =
  'transition/mark-received-from-disputed-with-paypal';
export const TRANSITION_CUSTOM_MARK_RECEIVED_FROM_DISPUTED =
  'transition/custom-mark-received-from-disputed';
// System moves transaction automatically from received state to complete state
// This makes it possible to to add notifications to that single transition.
export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';
export const TRANSITION_UPDATE_WALLET_BALANCE = 'transition/update-wallet-balance';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_CUSTOM_REVIEW_1_BY_CUSTOMER = 'transition/custom-review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

//Rental transitions

export const TRANSITION_INQUIRE = 'transition/inquire';

export const TRANSITION_RENTAL_REQUEST_PAYMENT = 'transition/request-payment';

export const TRANSITION_RENTAL_REQUEST_PAYMENT_PAYPAL = 'transition/request-payment-with-paypal';

export const TRANSITION_RENTAL_REQUEST_PAYMENT_AFTER_INQUIRY =
  'transition/request-payment-after-inquiry';

export const TRANSITION_RENTAL_REQUEST_PAYMENT_AFTER_INQUIRY_PAYPAL =
  'transition/request-payment-after-inquiry-with-paypal';

export const TRANSITION_RENTAL_EXPIRE_PAYMENT = 'transition/expire-payment';

export const TRANSITION_RENTAL_EXPIRE_PAYMENT_PAYPAL = 'transition/expire-payment-with-paypal';

export const TRANSITION_RENTAL_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_RENTAL_CONFIRM_PAYMENT_PAYPAL = 'transition/confirm-payment-with-paypal';

export const TRANSITION_RENTAL_PRODUCT_SHIPPED = 'transition/product-shipped';
export const TRANSITION_RENTAL_PRODUCT_SHIPPED_PAYPAL = 'transition/product-shipped-with-paypal';

export const TRANSITION_RENTAL_PRODUCT_RECEIVED = 'transition/product-received';
export const TRANSITION_RENTAL_PRODUCT_RECEIVED_PAYPAL = 'transition/product-received-with-paypal';

export const TRANSITION_RENTAL_RETURN_TO_SELLER = 'transition/return-to-seller';
export const TRANSITION_RENTAL_RETURN_TO_SELLER_PAYPAL = 'transition/return-to-seller-with-paypal';

export const TRANSITION_RENTAL_SELLER_RECEIVED_RETURN = 'transition/seller-received-return';
export const TRANSITION_RENTAL_SELLER_RECEIVED_RETURN_PAYPAL =
  'transition/seller-received-return-with-paypal';

export const TRANSITION_RENTAL_REVIEW_BY_CUSTOMER = 'transition/review-by-customer';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';

const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PENDING_PAYPAL_PAYMENT = 'pending-paypal-payment';
const STATE_CUSTOM_PENDING_PAYMENT = 'custom-pending-payment';

const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PAYMENT_WITH_PAYPAL_EXPIRED = 'payment-with-paypal-expired';
const STATE_CUSTOM_PAYMENT_EXPIRED = 'custom-payment-expired';

const STATE_PURCHASED = 'purchased';
const STATE_PURCHASED_WITH_PAYPAL = 'purchased-with-paypal';
const STATE_CUSTOM_PURCHASED = 'custom-purchased';

const STATE_DELIVERED = 'delivered';
const STATE_DELIVERED_WITH_PAYPAL = 'delivered-with-paypal';
const STATE_CUSTOM_DELIVERED = 'custom-delivered';

const STATE_RECEIVED = 'received';

const STATE_DISPUTED = 'disputed';
const STATE_DISPUTED_WITH_PAYPAL = 'disputed-with-paypal';
const STATE_CUSTOM_DISPUTED = 'custom-disputed';

const STATE_CANCELED = 'canceled';
const STATE_CANCELED_WITH_PAYPAL = 'canceled-with-paypal';

const STATE_COMPLETED = 'completed';
const STATE_UPDATE_WALLET = 'update-wallet';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';

//Rental States

const STATE_RENTAL_INQUIRY = 'inquiry';
const STATE_RENTAL_PENDING_PAYMENT = 'pending-payment';
const STATE_RENTAL_PAYMENT_EXPIRED = 'payment-expired';
const STATE_RENTAL_PENDING_SHIPPING = 'pending-shipping';
const STATE_RENTAL_SHIPPED = 'shipped';
const STATE_RENTAL_PRODUCT_RECEIVED = 'product-received';
const STATE_RENTAL_PRODUCT_RETURN_REQUEST = 'product-return-request';
const STATE_RENTAL_PRODUCT_RETURN_RECEIVED = 'product-return-received';
const STATE_RENTAL_PRODUCT_REVIEWED = 'reviewed';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-product-default-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        // [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL]: STATE_PENDING_PAYPAL_PAYMENT,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL]: STATE_PENDING_PAYPAL_PAYMENT,
        [TRANSITION_CUSTOM_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_CUSTOM_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PURCHASED,
      },
    },
    [STATE_PENDING_PAYPAL_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT_WITH_PAYPAL]: STATE_PAYMENT_WITH_PAYPAL_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL]: STATE_PURCHASED_WITH_PAYPAL,
      },
    },
    [STATE_CUSTOM_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_CUSTOM_EXPIRE_PAYMENT]: STATE_CUSTOM_PAYMENT_EXPIRED,
        [TRANSITION_CUSTOM_CONFIRM_PAYMENT]: STATE_CUSTOM_PURCHASED,
      },
    },
    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PAYMENT_WITH_PAYPAL_EXPIRED]: {},
    [STATE_CUSTOM_PAYMENT_EXPIRED]: {},
    [STATE_PURCHASED]: {
      on: {
        [TRANSITION_MARK_DELIVERED]: STATE_DELIVERED,
        [TRANSITION_MARK_RECEIVED_FROM_PURCHASED]: STATE_RECEIVED,
        [TRANSITION_AUTO_CANCEL]: STATE_CANCELED,
        [TRANSITION_CANCEL]: STATE_CANCELED,
      },
    },
    [STATE_PURCHASED_WITH_PAYPAL]: {
      on: {
        [TRANSITION_MARK_DELIVERED_WITH_PAYPAL]: STATE_DELIVERED_WITH_PAYPAL,
        [TRANSITION_MARK_RECEIVED_FROM_PURCHASED_WITH_PAYPAL]: STATE_RECEIVED,
        [TRANSITION_AUTO_CANCEL_WITH_PAYPAL]: STATE_CANCELED_WITH_PAYPAL,
        [TRANSITION_CANCEL_WITH_PAYPAL]: STATE_CANCELED_WITH_PAYPAL,
        [TRANSITION_REFUND_INITIATED]: STATE_CANCELED_WITH_PAYPAL,
      },
    },
    [STATE_CUSTOM_PURCHASED]: {
      on: {
        [TRANSITION_CUSTOM_MARK_DELIVERED]: STATE_CUSTOM_DELIVERED,
        [TRANSITION_CUSTOM_MARK_RECEIVED_FROM_PURCHASED]: STATE_RECEIVED,
        [TRANSITION_AUTO_CANCEL]: STATE_CANCELED,
        [TRANSITION_CUSTOM_CANCEL]: STATE_CANCELED,
      },
    },
    [STATE_CANCELED]: {},
    [STATE_CANCELED_WITH_PAYPAL]: {},
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_MARK_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_AUTO_MARK_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_DISPUTE]: STATE_DISPUTED,
      },
    },
    [STATE_DELIVERED_WITH_PAYPAL]: {
      on: {
        [TRANSITION_MARK_RECEIVED_WITH_PAYPAL]: STATE_RECEIVED,
        [TRANSITION_AUTO_MARK_RECEIVED_WITH_PAYPAL]: STATE_RECEIVED,
        [TRANSITION_DISPUTE_WITH_PAYPAL]: STATE_DISPUTED_WITH_PAYPAL,
      },
    },
    [STATE_CUSTOM_DELIVERED]: {
      on: {
        [TRANSITION_CUSTOM_MARK_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_CUSTOM_AUTO_MARK_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_CUSTOM_DISPUTE]: STATE_CUSTOM_DISPUTED,
      },
    },

    [STATE_DISPUTED]: {
      on: {
        [TRANSITION_AUTO_CANCEL_FROM_DISPUTED]: STATE_CANCELED,
        [TRANSITION_CANCEL_FROM_DISPUTED]: STATE_CANCELED,
        [TRANSITION_MARK_RECEIVED_FROM_DISPUTED]: STATE_RECEIVED,
      },
    },
    [STATE_DISPUTED_WITH_PAYPAL]: {
      on: {
        [TRANSITION_AUTO_CANCEL_FROM_DISPUTED_WITH_PAYPAL]: STATE_CANCELED_WITH_PAYPAL,
        [TRANSITION_CANCEL_FROM_DISPUTED_WITH_PAYPAL]: STATE_CANCELED_WITH_PAYPAL,
        [TRANSITION_MARK_RECEIVED_FROM_DISPUTED_WITH_PAYPAL]: STATE_RECEIVED,
      },
    },
    [STATE_CUSTOM_DISPUTED]: {
      on: {
        [TRANSITION_CUSTOM_AUTO_CANCEL_FROM_DISPUTED]: STATE_CANCELED,
        [TRANSITION_CUSTOM_CANCEL_FROM_DISPUTED]: STATE_CANCELED,
        [TRANSITION_CUSTOM_MARK_RECEIVED_FROM_DISPUTED]: STATE_RECEIVED,
      },
    },

    [STATE_RECEIVED]: {
      on: {
        [TRANSITION_AUTO_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_UPDATE_WALLET_BALANCE]: STATE_UPDATE_WALLET,
      },
    },

    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_UPDATE_WALLET_BALANCE]: STATE_UPDATE_WALLET,
      },
    },
    [STATE_UPDATE_WALLET]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_CUSTOM_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

const stateDashboardDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-product-default-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_PURCHASED]: {
      on: {
        [TRANSITION_MARK_DELIVERED]: STATE_DELIVERED,
        [TRANSITION_MARK_RECEIVED_FROM_PURCHASED]: STATE_RECEIVED,
      },
    },
    [STATE_PURCHASED_WITH_PAYPAL]: {
      on: {
        [TRANSITION_MARK_DELIVERED_WITH_PAYPAL]: STATE_DELIVERED_WITH_PAYPAL,
        [TRANSITION_MARK_RECEIVED_FROM_PURCHASED_WITH_PAYPAL]: STATE_RECEIVED,
      },
    },
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_MARK_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_AUTO_MARK_RECEIVED]: STATE_RECEIVED,
      },
    },
    [STATE_DELIVERED_WITH_PAYPAL]: {
      on: {
        [TRANSITION_MARK_RECEIVED_WITH_PAYPAL]: STATE_RECEIVED,
        [TRANSITION_AUTO_MARK_RECEIVED_WITH_PAYPAL]: STATE_RECEIVED,
      },
    },
    [STATE_DISPUTED_WITH_PAYPAL]: {
      on: {
        [TRANSITION_MARK_RECEIVED_FROM_DISPUTED_WITH_PAYPAL]: STATE_RECEIVED,
      },
    },

    [STATE_RECEIVED]: {
      on: {
        [TRANSITION_AUTO_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_UPDATE_WALLET_BALANCE]: STATE_UPDATE_WALLET,
      },
    },
    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_UPDATE_WALLET_BALANCE]: STATE_UPDATE_WALLET,
      },
    },
    [STATE_UPDATE_WALLET]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_CUSTOM_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

const stateRentalsDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'rental-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_INQUIRE]: STATE_RENTAL_INQUIRY,
        [TRANSITION_RENTAL_REQUEST_PAYMENT]: STATE_RENTAL_PENDING_PAYMENT,
        [TRANSITION_RENTAL_REQUEST_PAYMENT_PAYPAL]: STATE_RENTAL_PENDING_PAYMENT,
      },
    },
    [STATE_RENTAL_INQUIRY]: {
      on: {
        [TRANSITION_RENTAL_REQUEST_PAYMENT_AFTER_INQUIRY]: STATE_RENTAL_PENDING_PAYMENT,
        [TRANSITION_RENTAL_REQUEST_PAYMENT_AFTER_INQUIRY_PAYPAL]: STATE_RENTAL_PENDING_PAYMENT,
      },
    },

    [STATE_RENTAL_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_RENTAL_EXPIRE_PAYMENT]: STATE_RENTAL_PAYMENT_EXPIRED,
        [TRANSITION_RENTAL_EXPIRE_PAYMENT_PAYPAL]: STATE_RENTAL_PAYMENT_EXPIRED,
        [TRANSITION_RENTAL_CONFIRM_PAYMENT]: STATE_RENTAL_PENDING_SHIPPING,
        [TRANSITION_RENTAL_CONFIRM_PAYMENT_PAYPAL]: STATE_RENTAL_PENDING_SHIPPING,
      },
    },
    [STATE_RENTAL_PAYMENT_EXPIRED]: {},
    [STATE_RENTAL_PENDING_SHIPPING]: {
      on: {
        [TRANSITION_RENTAL_PRODUCT_SHIPPED]: STATE_RENTAL_SHIPPED,
        [TRANSITION_RENTAL_PRODUCT_SHIPPED_PAYPAL]: STATE_RENTAL_SHIPPED,
      },
    },
    [STATE_RENTAL_SHIPPED]: {
      on: {
        [TRANSITION_RENTAL_PRODUCT_RECEIVED]: STATE_RENTAL_PRODUCT_RECEIVED,
        [TRANSITION_RENTAL_PRODUCT_RECEIVED_PAYPAL]: STATE_RENTAL_PRODUCT_RECEIVED,
      },
    },
    [STATE_RENTAL_PRODUCT_RECEIVED]: {
      on: {
        [TRANSITION_RENTAL_RETURN_TO_SELLER]: STATE_RENTAL_PRODUCT_RETURN_REQUEST,
        [TRANSITION_RENTAL_RETURN_TO_SELLER_PAYPAL]: STATE_RENTAL_PRODUCT_RETURN_REQUEST,
      },
    },
    [STATE_RENTAL_PRODUCT_RETURN_REQUEST]: {
      on: {
        [TRANSITION_RENTAL_SELLER_RECEIVED_RETURN]: STATE_RENTAL_PRODUCT_RETURN_RECEIVED,
        [TRANSITION_RENTAL_SELLER_RECEIVED_RETURN_PAYPAL]: STATE_RENTAL_PRODUCT_RETURN_RECEIVED,
        [TRANSITION_RENTAL_REVIEW_BY_CUSTOMER]: STATE_RENTAL_PRODUCT_REVIEWED,
      },
    },
    [STATE_RENTAL_PRODUCT_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This is a list of all the transitions for dashboard page that this helps us show sales stats
export const DASHBOARD_TRANSITIONS = getTransitions(
  statesFromStateDescription(stateDashboardDescription)
).map(t => t.key);

export const RENTAL_TRANSITIONS = getTransitions(
  statesFromStateDescription(stateRentalsDescription)
).map(t => t.key);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = (stateDesc, rentalsDesc = false) => (state, isRentals) => {
  const desc = isRentals ? rentalsDesc : stateDesc;
  return getTransitions(statesFromStateDescription(desc))
    .filter(t => t.value === state)
    .map(t => t.key);
};

const getTransitionsToState = getTransitionsToStateFn(stateDescription, stateRentalsDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PURCHASED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

//Helper function to check if last transition is in a list of states.
const isLastTransitionInStates = (tx, states) => states.includes(txLastTransition(tx));

export const txIsEnquired = tx => {
  const enquiryStates = [
    ...getTransitionsToState(STATE_ENQUIRY),
    ...getTransitionsToState(STATE_RENTAL_INQUIRY, true),
  ];

  return isLastTransitionInStates(tx, enquiryStates);
};

export const txIsPaymentPending = tx => {
  const pendingPaymentStates = [
    ...getTransitionsToState(STATE_PENDING_PAYMENT),
    ...getTransitionsToState(STATE_PENDING_PAYPAL_PAYMENT),
    ...getTransitionsToState(STATE_CUSTOM_PENDING_PAYMENT),
    ...getTransitionsToState(STATE_RENTAL_PENDING_PAYMENT, true),
  ];

  return isLastTransitionInStates(tx, pendingPaymentStates);
};

export const txIsPurchasedAfterEnquiry = tx =>
  tx?.attributes?.lastTransition === TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY;

export const txIsPaymentExpired = tx => {
  const expiredPaymentStates = [
    ...getTransitionsToState(STATE_PAYMENT_EXPIRED),
    ...getTransitionsToState(STATE_PAYMENT_WITH_PAYPAL_EXPIRED),
    ...getTransitionsToState(STATE_CUSTOM_PAYMENT_EXPIRED),
    ...getTransitionsToState(STATE_RENTAL_PAYMENT_EXPIRED, true),
  ];
  return isLastTransitionInStates(tx, expiredPaymentStates);
};

export const txIsPurchased = tx => {
  const purchasedStates = [
    ...getTransitionsToState(STATE_PURCHASED),
    ...getTransitionsToState(STATE_PURCHASED_WITH_PAYPAL),
    ...getTransitionsToState(STATE_CUSTOM_PURCHASED),
  ];
  return isLastTransitionInStates(tx, purchasedStates);
};

export const txIsPendingShipping = tx => {
  const purchasedStates = [...getTransitionsToState(STATE_RENTAL_PENDING_SHIPPING, true)];
  return isLastTransitionInStates(tx, purchasedStates);
};

export const txIsShipped = tx => {
  const purchasedStates = [...getTransitionsToState(STATE_RENTAL_SHIPPED, true)];
  return isLastTransitionInStates(tx, purchasedStates);
};

export const txIsProductReceived = tx => {
  const purchasedStates = [...getTransitionsToState(STATE_RENTAL_PRODUCT_RECEIVED, true)];
  return isLastTransitionInStates(tx, purchasedStates);
};

export const txIsProductReturnRequest = tx => {
  const purchasedStates = [...getTransitionsToState(STATE_RENTAL_PRODUCT_RETURN_REQUEST, true)];
  return isLastTransitionInStates(tx, purchasedStates);
};

export const txIsProductReturnRequestReceived = tx => {
  const purchasedStates = [...getTransitionsToState(STATE_RENTAL_PRODUCT_RETURN_RECEIVED, true)];
  return isLastTransitionInStates(tx, purchasedStates);
};

export const txIsProductReviewed = tx => {
  const purchasedStates = [...getTransitionsToState(STATE_RENTAL_PRODUCT_REVIEWED, true)];
  return isLastTransitionInStates(tx, purchasedStates);
};

export const txIsCanceled = tx => {
  const canceledStates = [
    ...getTransitionsToState(STATE_CANCELED),
    ...getTransitionsToState(STATE_CANCELED_WITH_PAYPAL),
  ];
  return isLastTransitionInStates(tx, canceledStates);
};

export const txIsDelivered = tx => {
  const deliveredStates = [
    ...getTransitionsToState(STATE_DELIVERED),
    ...getTransitionsToState(STATE_DELIVERED_WITH_PAYPAL),
    ...getTransitionsToState(STATE_CUSTOM_DELIVERED),
  ];
  return isLastTransitionInStates(tx, deliveredStates);
};

export const txIsDisputed = tx => {
  const disputedStates = [
    ...getTransitionsToState(STATE_DISPUTED),
    ...getTransitionsToState(STATE_DISPUTED_WITH_PAYPAL),
    ...getTransitionsToState(STATE_CUSTOM_DISPUTED),
  ];
  return isLastTransitionInStates(tx, disputedStates);
};

export const txIsReceived = tx =>
  getTransitionsToState(STATE_RECEIVED).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsUpdateWallet = tx =>
  getTransitionsToState(STATE_UPDATE_WALLET).includes(txLastTransition(tx));

export const txIsReviewedByCustomer = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

export const txHasReviewCompleted = tx =>
  [TRANSITION_CUSTOM_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_1_BY_CUSTOMER].includes(
    ensureTransaction(tx).attributes.lastTransition
  );
/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// Helper function to check if the transaction has passed a certain state
export const txHasPassedPaymentPending = hasPassedStateFn(STATE_PENDING_PAYMENT);
export const txHasPassedPaymentPendingWithPaypal = hasPassedStateFn(STATE_PENDING_PAYPAL_PAYMENT);
export const txHasBeenReceived = hasPassedStateFn(STATE_RECEIVED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer
    ? TRANSITION_REVIEW_1_BY_CUSTOMER || TRANSITION_CUSTOM_REVIEW_1_BY_CUSTOMER
    : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
    TRANSITION_CUSTOM_CONFIRM_PAYMENT,
    TRANSITION_AUTO_CANCEL,
    TRANSITION_AUTO_CANCEL_WITH_PAYPAL,
    TRANSITION_CANCEL,
    TRANSITION_CANCEL_WITH_PAYPAL,
    TRANSITION_REFUND_INITIATED,
    TRANSITION_CUSTOM_CANCEL,
    TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
    TRANSITION_MARK_RECEIVED_FROM_PURCHASED_WITH_PAYPAL,
    TRANSITION_CUSTOM_MARK_RECEIVED_FROM_PURCHASED,
    TRANSITION_MARK_DELIVERED,
    TRANSITION_MARK_DELIVERED_WITH_PAYPAL,
    TRANSITION_CUSTOM_MARK_DELIVERED,
    TRANSITION_DISPUTE,
    TRANSITION_DISPUTE_WITH_PAYPAL,
    TRANSITION_CUSTOM_DISPUTE,
    TRANSITION_MARK_RECEIVED,
    TRANSITION_MARK_RECEIVED_WITH_PAYPAL,
    TRANSITION_CUSTOM_MARK_RECEIVED,
    TRANSITION_AUTO_MARK_RECEIVED,
    TRANSITION_AUTO_MARK_RECEIVED_WITH_PAYPAL,
    TRANSITION_CUSTOM_AUTO_MARK_RECEIVED,
    TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
    TRANSITION_MARK_RECEIVED_FROM_DISPUTED_WITH_PAYPAL,
    TRANSITION_CUSTOM_MARK_RECEIVED_FROM_DISPUTED,
    TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
    TRANSITION_AUTO_CANCEL_FROM_DISPUTED_WITH_PAYPAL,
    TRANSITION_CUSTOM_AUTO_CANCEL_FROM_DISPUTED,
    TRANSITION_CANCEL_FROM_DISPUTED,
    TRANSITION_CANCEL_FROM_DISPUTED_WITH_PAYPAL,
    TRANSITION_CUSTOM_CANCEL_FROM_DISPUTED,
    TRANSITION_CUSTOM_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_CUSTOM_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
  ].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL,
    TRANSITION_CUSTOM_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_CUSTOM_CONFIRM_PAYMENT,
  ].includes(transition);
};

export const OFFER_TRANSITIONS = [
  TRANSITION_SEND_OFFER,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
];

export const getProductShippedTransition = isPaypal => {
  return isPaypal ? TRANSITION_RENTAL_PRODUCT_SHIPPED_PAYPAL : TRANSITION_RENTAL_PRODUCT_SHIPPED;
};

export const getProductReceivedRequestTransition = isPaypal => {
  return isPaypal ? TRANSITION_RENTAL_PRODUCT_RECEIVED_PAYPAL : TRANSITION_RENTAL_PRODUCT_RECEIVED;
};

export const getProductReturnToSellerTransition = isPaypal => {
  return isPaypal ? TRANSITION_RENTAL_RETURN_TO_SELLER_PAYPAL : TRANSITION_RENTAL_RETURN_TO_SELLER;
};

export const getProductSellerReceivedReturnTransition = isPaypal => {
  return isPaypal
    ? TRANSITION_RENTAL_SELLER_RECEIVED_RETURN_PAYPAL
    : TRANSITION_RENTAL_SELLER_RECEIVED_RETURN;
};
