// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory
import fetch from 'cross-fetch';
import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  if (typeof window !== 'undefined') {
    // Otherwise, use the same domain and port as the frontend
    return `${window.location.origin}`;
  }

  return process.env.REACT_APP_CANONICAL_ROOT_URL;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const handleRequest = (url, options) => {
  return fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const get = (path, params = {}, headers) => {
  const url = `${apiBaseUrl()}${path}`;
  const queryParams = new URLSearchParams(params).toString();
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
      ...headers,
    },
  };
  return handleRequest(`${url}${queryParams ? '?' : ''}${queryParams}`, options);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

export const onCreateListing = body => {
  return post('/api/onCreateListing', body);
};

export const deleteStock = body => {
  return post('/api/deleteStock', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

export const verifyCoupon = body => {
  return post('/api/verify-coupon', body);
};

export const updateListing = body => {
  return post('/api/updateListing', body);
};

export const queryUser = body => {
  return post('/api/query-user', body);
};

export const queryUsers = body => {
  return get('/api/query-users', body);
};

export const queryTransaction = body => {
  return post('/api/query-transaction', body);
};

export const showCurrentUser = body => {
  return post('/api/show-user', body);
};

export const createPayout = body => {
  return post('/api/create-payout', body);
};

export const shopSmallUsers = ({}) => {
  return get('/api/shop-small-users', {});
};

export const queryUsersByBookmark = body => {
  return post('/api/query-users-by-bookmark', body);
};

export const updateOfferSeenNotification = body => {
  return post('/api/update-offer-notifications', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

// PayPal
const paypalOnboardingLink = body => {
  return post('/api/paypal/onboarding-links', body);
};

const paypalMerchantOnboardingStatus = () => {
  return get('/api/paypal/merchant-signup-status');
};

const paypalCreateOrder = body => {
  return post('/api/paypal/create-order', body);
};

const paypalCaptureOrder = body => {
  return post('/api/paypal/capture-order', body);
};

const paypalCompleteOrder = body => {
  return post('/api/paypal/complete-order', body);
};

const paypalAutoComplete = body => {
  return post('/api/paypal/auto-complete', body);
};

export const initiatePrivilegedWithPaypal = body => {
  return post('/api/paypal/initiate-privileged', body);
};

export const initiateSpecWithPaypal = body => {
  return post('/api/paypal/initiate-spec', body);
};

export const getOrderIdWithPaypal = body => {
  return post('/api/paypal/get-order-id', body);
};

export const transitionPrivilegedWithPaypal = body => {
  return post('/api/paypal/transition-privileged', body);
};

export const createDrop = body => {
  return post('/api/create-drop', body);
};

export const updateWalletBalance = body => {
  return post('/api/update-wallet-balance', body);
};

export const addListMember = body => {
  return post('/api/mail/add-list-member', body);
};

export const addProduct = body => {
  return post('/api/mailchimp/add-product', body);
};
export const removeProduct = body => {
  return post('/api/mailchimp/remove-product', body);
};

export const PayPalApi = {
  getOnboardingLink: paypalOnboardingLink,
  getMerchantOnboardingStatus: paypalMerchantOnboardingStatus,
  createOrder: paypalCreateOrder,
  captureOrder: paypalCaptureOrder,
  completeOrder: paypalCompleteOrder,
  autoCompletePaypalOrder: paypalAutoComplete,
};

export const createAddress = body => {
  return post('/api/shippo/create-address', body);
};

export const getCarriers = body => {
  return post('/api/shippo/get-carriers', body);
};

export const getRate = body => {
  return post('/api/shippo/get-rate', body);
};

export const createShipment = body => {
  return post('/api/shippo/create-shipment', body);
};

export const trackingStatus = body => {
  return post('/api/shippo/get-tracking-status', body);
};

export const createLabel = body => {
  return post('/api/shippo/create-label', body);
};

export const showStripeAccount = body => {
  return post('/api/stripe/retrieve-account', body);
};

export const addProductToFeed = body => {
  return post('/api/google/add-product-to-feed', body);
};

export const createReturnLabel = body => {
  return post('/api/shippo/create-return-label', body);
};

export const ShippoApi = {
  createAddressWithShippo: createAddress,
  getCarriersWithShippo: getCarriers,
  getRateWithShippo: getRate,
  createShippoShipment: createShipment,
  getTrackingStatus: trackingStatus,
  createInstantLabel: createLabel,
};
