/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import NamedLink from '../NamedLink/NamedLink';

const LineItemUnknownItemsMaybe = props => {
  const { lineItems, isProvider, intl, savedCart, isRentals } = props;

  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal);

  const items = isProvider
    ? allItems
        .filter(item => item.includeFor.includes('provider'))
        .filter(l => l?.code !== 'line-item/units-Discount')
    : allItems
        .filter(item => item.includeFor.includes('customer'))
        .filter(l => l?.code !== 'line-item/units-Discount');

  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const quantity = item.quantity;
        const cartItem = savedCart && savedCart[i];

        const label =
          quantity && quantity > 1
            ? `${humanizeLineItemCode(item.code)} x ${quantity}`
            : humanizeLineItemCode(item.code);

        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            {cartItem && !isRentals ? (
              <NamedLink
                params={cartItem && cartItem.params}
                name="ListingPage"
                className={css.itemLabel}
              >
                {cartItem && cartItem.firstImage ? (
                  <img src={cartItem?.firstImage} alt={cartItem?.params?.slug} />
                ) : (
                  label && label.slice(5)
                )}
              </NamedLink>
            ) : (
              <span className={css.itemLabel}>{isRentals ? label : label && label.slice(5)}</span>
            )}
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
