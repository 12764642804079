import React from 'react';

function IconBag() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      id="i-cart"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      style={{ fill: 'none', stroke: '#4a4a4a' }}
    >
      <path d="M6 6 L30 6 27 19 9 19 M27 23 L10 23 5 2 2 2" />
      <circle cx="25" cy="27" r="2" />
      <circle cx="12" cy="27" r="2" />
    </svg>
  );
}

export default IconBag;
