import unionWith from 'lodash/unionWith';
import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { profileFilter } from '../../config/enums';
import { isArrayLength } from '../../util/dataExtractors';
import { customSortListingsByKeyword } from '../../util/genericHelpers';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { IS_COSTUMES_RENTALS } from '../../util/enums';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages

const RESULT_PAGE_SIZE = 100;

// Function to calculate pagination meta
function calculatePaginationMeta(itemsLength) {
  const totalPages = Math.ceil(itemsLength / RESULT_PAGE_SIZE);
  return {
    totalItems: itemsLength,
    totalPages,
    page: 1,
    paginationLimit: totalPages,
    perPage: RESULT_PAGE_SIZE,
  };
}

const extraParams = {
  perPage: RESULT_PAGE_SIZE,
  include: ['author', 'images', 'author.profileImage'],
  'fields.listing': ['title', 'description', 'geolocation', 'createdAt', 'price', 'publicData'],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
  'fields.image': [
    `variants.listing-card`,
    `variants.listing-card-2x`,
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  ...createImageVariantConfig(`listing-card`, 400, 1),
  ...createImageVariantConfig(`listing-card-2x`, 800, 1),
  'limit.images': 5,
};

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/CostumeSearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/CostumeSearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/CostumeSearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/CostumeSearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/CostumeSearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/CostumeSearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/CostumeSearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SAVE_LISTINGS_STATE = 'app/CostumeSearchPage/SAVE_LISTINGS_STATE';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  searchAllListingsInProgress: true,
};

const resultIds = data => data.data.map(l => l.id);
const resultInfiniteScrollIds = (data, oldData) => [...oldData, ...data.data.map(al => al.id)];

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        currentPageResultIds: [],
        searchListingsError: null,
        searchAllListingsInProgress: true,
      };
    case SEARCH_LISTINGS_SUCCESS:
      let currentPageResultIds = resultInfiniteScrollIds(payload.data, state.currentPageResultIds);
      currentPageResultIds = currentPageResultIds.filter(
        (cpri, index, self) => index === self.findIndex(t => t.uuid === cpri.uuid)
      );
      return {
        ...state,
        currentPageResultIds,
        pagination: payload.data.meta,
        searchInProgress: false,
      };

    case SAVE_LISTINGS_STATE:
      return {
        ...state,
        currentPageResultIds: payload.currentPageResultIds,
        pagination: payload.pagination,
        searchInProgress: false,
      };

    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const saveListingState = response => ({
  type: SAVE_LISTINGS_STATE,
  payload: { currentPageResultIds: response.currentPageResultIds, pagination: response.pagination },
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams, isInfiniteScroll = false) => (
  dispatch,
  getState,
  sdk
) => {
  const saveScroll =
    typeof window !== 'undefined' && window.sessionStorage.getItem('saveScroll')
      ? Boolean(window.sessionStorage.getItem('saveScroll'))
      : false;
  const hasListingsAlready = getState().CostumeSearchPage.currentPageResultIds;
  const pagination = getState().CostumeSearchPage.pagination;

  if (isArrayLength(hasListingsAlready) && saveScroll) {
    dispatch(
      saveListingState({
        currentPageResultIds: hasListingsAlready,
        pagination,
      })
    );
    typeof window !== 'undefined' && window.sessionStorage.removeItem('saveScroll');
  }

  !isInfiniteScroll && !saveScroll && dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = false;
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };

  const { perPage, price, dates, sort, pub_category, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...sortMaybe,
    ...datesMaybe,
    minStock: 1,
    per_page: perPage,
    pub_category: profileFilter.COSTUMES,
  };

  if (typeof window !== 'undefined') {
    const isRentals = window.location?.pathname === '/costumes-rentals';
    if (isRentals) {
      Object.assign(params, { pub_isRental: isRentals });
    }
  }

  return sdk.listings
    .query(params)
    .then(async response => {
      const { data } = response || {};
      let { data: listings } = data || {};
      if (typeof window !== 'undefined') {
        const isRentals = window.location?.pathname === IS_COSTUMES_RENTALS;
        if (isRentals && isArrayLength(listings)) {
          listings = listings.filter(l => l?.attributes?.publicData?.isRental === true);
          response.data.data = listings;
          response.data.meta = calculatePaginationMeta(listings?.length);
        }
      }

      if (isArrayLength(listings) && params?.keywords) {
        const sortedListings = customSortListingsByKeyword(listings, params);
        response.data.data = sortedListings;
        response.data.meta = calculatePaginationMeta(sortedListings.length);
      }

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, address, ...rest } = queryParams;

  return searchListings({
    ...rest,
    page,
    ...extraParams,
  });
};

export const loadMore = params => {
  return searchListings(
    {
      ...params,
      ...extraParams,
    },
    true
  );
};
