import React from 'react';

function IconLeftArrow() {
  return (
    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.60582 10.9499L11.5209 2.65916C11.6835 2.50868 11.8109 2.3212 11.8934 2.11113C11.9759 1.90107 12.0113 1.674 11.9969 1.44738C11.9824 1.22076 11.9186 1.00061 11.8102 0.803854C11.7018 0.607096 11.5518 0.438962 11.3716 0.312369C11.0717 0.0849373 10.7046 -0.0241251 10.3353 0.00447951C9.966 0.0330841 9.61824 0.197511 9.35359 0.468668L0.460189 9.85468C0.315154 9.99388 0.199352 10.1631 0.120131 10.3517C0.04091 10.5402 2.68221e-07 10.7439 2.68221e-07 10.9499C2.68221e-07 11.1559 0.04091 11.3596 0.120131 11.5482C0.199352 11.7367 0.315154 11.906 0.460189 12.0452L9.35359 21.4312C9.61824 21.7023 9.966 21.8668 10.3353 21.8954C10.7046 21.924 11.0717 21.8149 11.3716 21.5875C11.5518 21.4609 11.7018 21.2927 11.8102 21.096C11.9186 20.8992 11.9824 20.6791 11.9969 20.4525C12.0113 20.2258 11.9759 19.9988 11.8934 19.7887C11.8109 19.5786 11.6835 19.3912 11.5209 19.2407L3.60582 10.9499Z"
        fill="white"
      />
    </svg>
  );
}

export default IconLeftArrow;
