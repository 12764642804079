import React from 'react';
import ContentLoader from 'react-content-loader';

const AuthorSkeleton = props => {
  const { viewport } = props;
  const MAX_MOBILE_SCREEN_WIDTH = 550;
  const isMobileViewLoader = viewport.width >= MAX_MOBILE_SCREEN_WIDTH;
  return (
    <>
      {isMobileViewLoader ? (
        <ContentLoader
          speed={2}
          width={500}
          height={204}
          viewBox="0 0 500 204"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <circle cx="54" cy="54" r="50" />
          <rect x="118" y="9" rx="7" ry="7" width="172" height="16" />
          <rect x="118" y="38" rx="7" ry="7" width="321" height="16" />
          <rect x="117" y="66" rx="7" ry="7" width="273" height="16" />
          <rect x="118" y="96" rx="7" ry="7" width="227" height="16" />
        </ContentLoader>
      ) : (
        <ContentLoader
          speed={2}
          width={500}
          height={250}
          viewBox="0 0 500 250"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <circle cx="54" cy="54" r="50" />
          <rect x="4" y="117" rx="7" ry="7" width="172" height="16" />
          <rect x="4" y="146" rx="7" ry="7" width="321" height="16" />
          <rect x="3" y="174" rx="7" ry="7" width="273" height="16" />
          <rect x="4" y="204" rx="7" ry="7" width="227" height="16" />
        </ContentLoader>
      )}
    </>
  );
};

export default AuthorSkeleton;
