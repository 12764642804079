import React from 'react';

function IconDiamond() {
  return (
    <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M2.009,7.956C2.008,7.971,2,7.984,2,8l0,.013c.009.721.677,1.107,9.175,13.556a1,1,0,0,0,1.644,0C22.167,7.967,21.99,8.563,22,8.013L22,8c0-.016-.008-.029-.009-.044-.025-.563-.474-.906-3.134-5.471A1,1,0,0,0,18,2H6a1,1,0,0,0-.857.485C2.363,7.256,2.032,7.423,2.009,7.956ZM12,19.243,4.908,9H19.092ZM6.566,4H17.434l1.8,3H4.766Z"></path>
      </g>
    </svg>
  );
}

export default IconDiamond;
