import React from 'react';

function DeleteTagIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99009 0C12.4114 0 16 3.56877 16 7.99009C16 12.4114 12.4114 16 7.99009 16C3.56877 16 0 12.4114 0 7.99009C0 3.56877 3.56877 0 7.99009 0ZM7.99009 6.42379L10.2503 4.16357C11.2813 3.13259 12.8674 4.69888 11.8364 5.72986L9.57621 7.99009L11.8364 10.2503C12.8674 11.2813 11.2813 12.8674 10.2503 11.8364L7.99009 9.57621L5.72986 11.8364C4.69888 12.8674 3.13259 11.2813 4.16357 10.2503L6.42379 7.99009L4.16357 5.72986C3.13259 4.69888 4.69888 3.13259 5.72986 4.16357L7.99009 6.42379Z"
        fill="#696969"
      />
    </svg>
  );
}

export default DeleteTagIcon;
