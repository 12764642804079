import pick from 'lodash/pick';
import config from '../../config';
import {
  deleteStock,
  initiatePrivileged,
  transitionPrivileged,
  initiatePrivilegedWithPaypal,
  transitionPrivilegedWithPaypal,
  showCurrentUser,
  ShippoApi,
  getOrderIdWithPaypal,
  initiateSpecWithPaypal,
  verifyCoupon,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL,
  TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL,
  TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
  isPrivileged,
  TRANSITION_CUSTOM_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CUSTOM_CONFIRM_PAYMENT,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isArrayLength } from '../../util/dataExtractors';
import { renameObjKeys } from '../../util/search';

const { UUID } = sdkTypes;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const FETCH_CARRIER_REQUEST = 'app/CheckoutPage/FETCH_CARRIER_REQUEST';
export const FETCH_CARRIER_SUCCESS = 'app/CheckoutPage/FETCH_CARRIER_SUCCESS';
export const FETCH_CARRIER_ERROR = 'app/CheckoutPage/FETCH_CARRIER_ERROR';

export const FETCH_IS_INTERNATIONAL_SHIPPING = 'app/CheckoutPage/FETCH_IS_INTERNATIONAL_SHIPPING';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  fetchCarriersInProgress: false,
  carriers: [],
  carriersError: null,
  isInternational: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_IS_INTERNATIONAL_SHIPPING:
      return { ...state, isInternational: payload?.isInternational };
    case FETCH_CARRIER_REQUEST:
      return { ...state, fetchCarriersInProgress: true };
    case FETCH_CARRIER_SUCCESS:
      return { ...state, fetchCarriersInProgress: false, carriers: payload?.carriers || [] };
    case FETCH_CARRIER_ERROR:
      return { ...state, fetchCarriersInProgress: false, carriersError: true };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const fetchCarriersRequest = () => ({ type: FETCH_CARRIER_REQUEST });
export const fetchCarriersSuccess = carriers => ({
  type: FETCH_CARRIER_SUCCESS,
  payload: { carriers },
});
export const fetchIsInternationalShipping = isInternational => ({
  type: FETCH_IS_INTERNATIONAL_SHIPPING,
  payload: { isInternational },
});
export const fetchCarriersError = () => ({ type: FETCH_CARRIER_ERROR });

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const {
    deliveryMethod,
    quantity,
    shippingDetails = {},
    couponParams = {},
    bookingDates,
    isRental = false,
    isCustomFlow = false,
    isOfferProcess = false,
    ...otherOrderParams
  } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    shippingDetails,
    couponParams,
    bookingDates: bookingDates || {},
  };

  const bookingDatesMaybe = bookingDates?.startDate
    ? renameObjKeys(bookingDates, {
        startDate: 'bookingStart',
        endDate: 'bookingEnd',
      })
    : {};

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingDatesMaybe,
    ...otherOrderParams,
  };

  const process = isOfferProcess
    ? config.offerProcessAlias
    : isCustomFlow
    ? config.bookingProcessAlias
    : isRental
    ? config.rentalProcessAlias
    : config.transactionProcessAlias;

  let transition = isCustomFlow
    ? isTransition
      ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
      : TRANSITION_CONFIRM_PAYMENT
    : isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;

  if (isOfferProcess && isCustomFlow) {
    transition = TRANSITION_CUSTOM_CONFIRM_PAYMENT;
  } else if (isOfferProcess && !isCustomFlow) {
    transition = TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY;
  }

  const isPrivilegedTransition = isPrivileged(transition);

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: process,
        transition,
        params: transitionParams,
      };
  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  const handleSucces = async response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({
      isSpeculative: false,
      orderData,
      bodyParams,
      queryParams,
    })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const initiateWalletOrder = orderParams => async (dispatch, getState, sdk) => {
  // dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.

  const transition = TRANSITION_REQUEST_PAYMENT;

  const {
    deliveryMethod,
    quantity,
    shippingDetails = {},
    bookingDates,
    ...otherOrderParams
  } = orderParams;

  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    shippingDetails,
    bookingDates: bookingDates || {},
  };

  const bookingDatesMaybe = bookingDates?.startDate
    ? renameObjKeys(bookingDates, {
        startDate: 'bookingStart',
        endDate: 'bookingEnd',
      })
    : {};

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingDatesMaybe,
    ...otherOrderParams,
  };

  const bodyParams = {
    processAlias: config.walletProcessAlias,
    transition,
    params: transitionParams,
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  try {
    const response = await initiatePrivileged({
      isSpeculative: false,
      orderData,
      bodyParams,
      queryParams,
    });

    if (response) {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      const listing = order && order.listing;
      const listingId = listing && listing.id && listing.id.uuid;
      const cart = orderParams && orderParams.cartItems;
      const otherItems =
        cart && cart.cartItems && cart.cartItems.filter(item => item.listingId !== listingId);

      const items =
        otherItems &&
        otherItems.map(item => {
          const listingId = new UUID(item.listingId);
          return { listingId, stockCount: item.stockCount, oldStock: item.oldStock };
        });

      Array.isArray(items) && items.length && deleteStock({ otherItems: items });

      return order;
    }
  } catch (error) {
    dispatch(initiateOrderError(storableError(error)));
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const {
    deliveryMethod,
    quantity,
    shippingDetails = {},
    couponParams = {},
    isCustomFlow,
    isRental = false,
    isOfferProcess = false,
    bookingDates,
    shouldRemoveShipping,
    ...otherOrderParams
  } = orderParams;

  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    shippingDetails,
    couponParams,
    shouldRemoveShipping,
    bookingDates: bookingDates || {},
  };

  const bookingDatesMaybe = bookingDates?.startDate
    ? renameObjKeys(bookingDates, {
        startDate: 'bookingStart',
        endDate: 'bookingEnd',
      })
    : {};

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingDatesMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  // Determine the process alias based on the flags.
  const process = isOfferProcess
    ? config.offerProcessAlias
    : isCustomFlow
    ? config.bookingProcessAlias
    : isRental
    ? config.rentalProcessAlias
    : config.transactionProcessAlias;

  let transition;

  if (isOfferProcess) {
    transition = isCustomFlow
      ? TRANSITION_CUSTOM_CONFIRM_PAYMENT
      : TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY;
  } else if (isCustomFlow) {
    transition = isTransition
      ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
      : TRANSITION_CONFIRM_PAYMENT;
  } else {
    transition = isTransition
      ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
      : TRANSITION_REQUEST_PAYMENT;
  }

  const isPrivilegedTransition = isPrivileged(transition);
  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: process,
        transition,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

//Creating paypal order id without sharetribe transition
export const createOrderIdWithPaypal = (orderParams, transactionId) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateOrderRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL
    : TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL;

  const isPrivilegedTransition = isPrivileged(transition);

  const {
    deliveryMethod,
    quantity,
    shippingDetails = {},
    couponParams = {},
    shippingInfo = null,
    isRental = false,
    bookingDates = {},
    merchantId,
    isOfferProcess,
    ...otherOrderParams
  } = orderParams;

  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

  const orderData = {
    deliveryMethod,
    shippingDetails,
    couponParams,
    shippingInfo,
    bookingDates: bookingDates || {},
  };

  const bookingDatesMaybe = bookingDates?.startDate
    ? renameObjKeys(bookingDates, {
        startDate: 'bookingStart',
        endDate: 'bookingEnd',
      })
    : {};

  const transitionParams = {
    ...quantityMaybe,
    ...bookingDatesMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: isRental ? config.rentalProcessAlias : config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  const handleSucces = async response => {
    return response;
  };

  const handleError = e => {
    throw e;
  };

  return getOrderIdWithPaypal({
    merchantId,
    isSpeculative: false,
    orderData,
    bodyParams,
    queryParams,
  })
    .then(handleSucces)
    .catch(handleError);
};

export const initiateOrderWithPayPal = (orderParams, extraParams, transactionId) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateOrderRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL
    : TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL;

  const isPrivilegedTransition = isPrivileged(transition);

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  const handleSucces = async response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));

    // const listing = order && order.listing;
    // const listingId = listing && listing.id && listing.id.uuid;
    // const cart = orderParams && orderParams.cartItems;
    // const otherItems =
    //   cart && cart.cartItems && cart.cartItems.filter(item => item.listingId !== listingId);

    // const items =
    //   otherItems &&
    //   otherItems.map(item => {
    //     const listingId = new UUID(item.listingId);
    //     return { listingId, stockCount: item.stockCount, oldStock: item.oldStock };
    //   });

    // isArrayLength(items) && (await deleteStock({ otherItems: items }));

    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivilegedWithPaypal({
      isSpeculative: false,
      orderParams,
      extraParams,
    })
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivilegedWithPaypal({
      isSpeculative: false,
      orderParams,
      extraParams,
    })
      .then(handleSucces)
      .catch(handleError);
  }
};

export const speculateTransactionWithPayPal = (orderParams, transactionId) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(speculateTransactionRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL
    : TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL;
  const isPrivilegedTransition = isPrivileged(transition);

  const {
    deliveryMethod,
    quantity,
    shippingDetails = {},
    couponParams = {},
    merchantId,
    isRental = false,
    bookingDates,
    shouldRemoveShipping,
    ...otherOrderParams
  } = orderParams;

  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    shippingDetails,
    couponParams,
    shouldRemoveShipping,
    bookingDates: bookingDates || {},
  };

  const bookingDatesMaybe = bookingDates?.startDate
    ? renameObjKeys(bookingDates, {
        startDate: 'bookingStart',
        endDate: 'bookingEnd',
      })
    : {};

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingDatesMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: isRental ? config.rentalProcessAlias : config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivilegedWithPaypal({
      isSpeculative: true,
      orderData,
      bodyParams,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiateSpecWithPaypal({
      isSpeculative: true,
      orderData,
      bodyParams,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const confirmPaymentWithPayPal = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
    params: {
      protectedData: {
        paypalCaptureId: orderParams.captureId,
      },
    },
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const showUser = params => async (dispatch, getState, sdk) => {
  const response = await showCurrentUser({ ...params });
  return response;
};

export const fetchShippingCarriers = params => async (dispatch, getState, sdk) => {
  dispatch(fetchCarriersRequest());
  try {
    const response = await ShippoApi.getCarriersWithShippo(params);
    dispatch(fetchCarriersSuccess(response?.carriers));
    dispatch(fetchIsInternationalShipping(response?.isInternational));
    return response;
  } catch (error) {
    dispatch(fetchCarriersError());
  }
};

export const verifyCouponCode = params => async (dispatch, getState, sdk) => {
  const response = await verifyCoupon({ ...params });
  return response;
};
