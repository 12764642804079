import React, { useState, useContext } from 'react';

// First create the context
const MyContext = React.createContext();

// Then create a StateHolder wrapper component
// to hold the state that the components need.
const StateHolder = props => {
  const [cartItems, setCartItems] = useState([]);
  const [clearDeepFilter, setClearDeepFilters] = useState(false);
  return (
    <MyContext.Provider
      value={{
        cartItems,
        setCartItems,
        clearDeepFilter,
        setClearDeepFilters,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
