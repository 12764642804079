import React from 'react';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './CategorySlider.module.css';

export default function CategoryItems(props) {
  return (
    <NamedLink name="SearchPage" to={{ search: props.item.search }}>
      <div className={classNames(css.imgWrapper, css.makesImage)}>
        <img src={props.item?.image} alt={props.item?.name} className={css.categories} />
      </div>
      {!props.hideTitle && <h2 className={css.cardItemTitle}>{props.item.name}</h2>}
    </NamedLink>
  );
}
