import React, { useState } from 'react';
import IconHome from '../../components/IconHome/IconHome';
import IconDiamond from '../../components/IconDiamond/IconDiamond';
import IconCustomer from '../../components/IconCustomer/IconCustomer';
import IconShop from '../../components/IconShop/IconShop';
import IconPromote from '../../components/IconPromote/IconPromote';

import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration, { draftId, draftSlug } from '../../routing/routeConfiguration';
import IconInbox from '../IconInbox/IconInbox';
import IconPayout from '../IconPayout/IconPayout';
import Icons from '../Icons/Icons';
import { selectCurrentUser } from '../../ducks/user.duck';
import { getUsername } from '../../util/dataExtractors';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ACCOUNT_PAGE, STRING } from '../../util/enums';

import css from './DashboardMobileSidebar.module.css';
import IconMenu from '../IconMenu/IconMenu';
import IconCross from '../IconCross/IconCross';

function DashboardMobileSidebar() {
  const currentUser = useSelector(selectCurrentUser);
  const history = useHistory();
  const location = useLocation();
  const username = getUsername(currentUser);
  const pathname = location && location.pathname;

  const isAccountPage =
    typeof pathname === STRING && pathname ? location.pathname.includes(ACCOUNT_PAGE) : false;

  const [toggle, setToggle] = useState({ account: isAccountPage ? isAccountPage : false });
  const [showMenu, setMenu] = useState(false);

  const { account = false } = toggle || {};

  const redirectToProfilePage = () => {
    const state = { state: currentUser?.id?.uuid };
    const params = username ? { username } : { id: currentUser?.id?.uuid };

    history.push(createResourceLocatorString('StorePage', routeConfiguration(), params, {}), state);
  };

  const redirectToPage = (name, params = {}) => {
    history.push(createResourceLocatorString(name, routeConfiguration(), params, {}));
  };

  const onToggle = type => {
    const selected = toggle[type];
    setToggle({ ...toggle, [type]: !selected });
  };

  const menu = (
    <div className={css.accordionMenu}>
      <div className={css.sidelink} onClick={() => redirectToPage('LandingPage')}>
        <span className={css.sideNavIcon}>
          <IconHome />
        </span>{' '}
        Home
      </div>
      <div className={css.sidelink} onClick={() => redirectToPage('DashboardPage')}>
        <IconPayout sideNavIcon={css.sideNavIcon} shopIcon={css.shopIcon} /> Payouts
      </div>
      <div className={css.sidelink} onClick={redirectToProfilePage}>
        <span className={classNames(css.sideNavIcon, css.shopIcon)}>
          <IconShop />
        </span>{' '}
        Shop
      </div>
      <div className={css.sidelink} onClick={() => redirectToPage('InboxPage', { tab: 'sales' })}>
        <span className={classNames(css.sideNavIcon, css.shopIcon)}>
          <IconInbox />
        </span>{' '}
        Orders
      </div>
      <div className={css.sidelink} onClick={() => redirectToPage('OffersPage')}>
        <span className={classNames(css.sideNavIcon, css.shopIcon)}>
          <Icons name="offerIcon" />
        </span>{' '}
        Offers
      </div>
      <div className={css.sidelink} onClick={() => redirectToPage('ManageListingsPage')}>
        <span className={classNames(css.sideNavIcon, css.shopIcon)}>
          <Icons name="listings" />
        </span>{' '}
        Your listings
      </div>
      <div
        className={css.sidelink}
        onClick={() =>
          redirectToPage('EditListingPage', {
            slug: draftSlug,
            id: draftId,
            type: 'new',
            tab: 'rental',
          })
        }
      >
        <span className={classNames(css.sideNavIcon, css.shopIcon)}>
          <Icons name="rentals" />
        </span>{' '}
        Rentals
      </div>
      <div className={css.sidelink} onClick={() => redirectToPage('ProfileSettingsPage')}>
        <span className={classNames(css.sideNavIcon, css.shopIcon)}>
          <Icons name="profile" />
        </span>{' '}
        Profile Settings
      </div>
      <div className={css.sidelink}>
        <div className={css.sideDropdown} onClick={() => onToggle('account')}>
          <span>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <IconCustomer />
            </span>{' '}
            Account
          </span>
          {account ? (
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="upArrow" />
            </span>
          ) : (
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="downArrow" />
            </span>
          )}
        </div>
      </div>
      {account ? (
        <div className={css.sideChildLinks}>
          <div className={css.sidelink} onClick={() => redirectToPage('ContactDetailsPage')}>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="contact" />
            </span>{' '}
            Contact details
          </div>
          <div className={css.sidelink} onClick={() => redirectToPage('PasswordChangePage')}>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="password" />
            </span>{' '}
            Password
          </div>
          <div className={css.sidelink} onClick={() => redirectToPage('ShippingDetailsPage')}>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="shipping" />
            </span>{' '}
            Shipping details
          </div>
          <div className={css.sidelink} onClick={() => redirectToPage('StripePayoutPage')}>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="payout" />
            </span>{' '}
            Payout details
          </div>
          <div className={css.sidelink} onClick={() => redirectToPage('PayPalMerchantPage')}>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="paypal" />
            </span>{' '}
            Paypal account
          </div>
          <div className={css.sidelink} onClick={() => redirectToPage('PaymentMethodsPage')}>
            <span className={classNames(css.sideNavIcon, css.shopIcon)}>
              <Icons name="card" />
            </span>{' '}
            Payment methods
          </div>
        </div>
      ) : null}
    </div>
  );

  const selectMenu = (
    <div className={css.mobileDrawerMenu} onClick={() => setMenu(!showMenu)}>
      {showMenu ? <IconCross /> : <IconMenu />}
      {/* <span className={classNames(css.sideNavIcon, css.shopIcon)}>
        <Icons name={showMenu ? 'upArrow' : 'downArrow'} />
      </span>{' '} */}
    </div>
  );

  return (
    <div>
      {selectMenu}
      {showMenu ? menu : null}
    </div>
  );
}

export default DashboardMobileSidebar;
