import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { IconHeartFilled, IconHeart, ImageSlider } from '..';
import {
  getBookmarks,
  getBrandName,
  getLikes,
  getLikesCount,
  getProductImages,
  getRecentlyViewed,
  getStoreName,
  getUsername,
  getWishlist,
  isArrayLength,
  isRentalSearchPage,
} from '../../util/dataExtractors';
import IconBag from '../IconBag/IconBag';
import IconBagFilled from '../IconBagFilled/IconBagFilled';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import config from '../../config';
import {
  getKeyFromLocalStorage,
  isWindowDefined,
  setLocalStorageItems,
} from '../../util/cartHelpers';
import { useMyContext } from '../../contexts/StateHolder';
import { useDispatch, useSelector } from 'react-redux';

import css from './ListingCardMobile.module.css';
import { selectCurrentUser, updateSave } from '../../ducks/user.duck';
import { selectIsAuthenticated } from '../../ducks/Auth.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { addStoreProductMailChimp, removeStoreProductMailChimp } from '../../ducks/Global.duck';
import { showLimitedText } from '../../util/genericHelpers';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const ListingCardMobileComponent = props => {
  const { className, rootClassName, listing, isOwnListing = false, sizeText } = props;

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const history = useHistory();
  const dispatch = useDispatch();

  const [cacheCount, setCacheCount] = useState(0); // This is used to show count +1 or -1 directly to users to avoid lag
  const { setCartItems } = useMyContext();

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);

  const author = ensureUser(currentListing.author);
  const storeName = getStoreName(author);
  const username = getUsername(author);

  const authorDisplayName = author && userDisplayNameAsString(author, '');
  const display = storeName ? storeName : authorDisplayName;

  const photos = publicData && publicData.photos;
  const firstPhoto = photos && photos.length && photos[0];

  const likes = getLikes(currentListing);
  const recentlyViewed = getRecentlyViewed(currentUser) || [];
  const likesCount = getLikesCount(currentListing);
  const wishlist = getWishlist(currentUser);

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const onUpdateRecentlyViewed = () => {
    if (isAuthenticated) {
      recentlyViewed.push(id);
      const viewed = [...new Set(recentlyViewed)];
      Array.isArray(viewed) && viewed.reverse();
      onUpdateProfile({
        publicData: {
          recentlyViewed: viewed,
        },
      });
    }
  };

  const productPrice = price && price.amount / 100;
  const images = firstPhoto ? photos : getProductImages(currentListing.images);

  const localBookmarks = getKeyFromLocalStorage('localBookmarks');

  const bookmarks = isAuthenticated ? getBookmarks(currentUser) : localBookmarks;

  const onUpdateCurrentUser = (array, isLikes = false) => {
    const params = isLikes
      ? { wishlist: Array.from(new Set(array)) }
      : { bookmarks: Array.from(new Set(array)) };
    onUpdateProfile({
      publicData: { ...params },
    });
  };
  const productParams = {
    id,
    title: title,
    variants: [
      {
        id,
        title: title,
        url: `www.tutulist.com/l/${slug}/${id}`,
        price: productPrice,
        image_url: isArrayLength(images) && images[0],
      },
    ],
    url: `www.tutulist.com/l/${slug}/${id}`,
    vendor: display,
    image_url: isArrayLength(images) && images[0],
  };

  const imgAlt = title + ' ' + (display || '') + (' ' + (username || ''));
  const isSyncedWithMC = publicData?.isSyncedWithMC;
  const hasVariants = isArrayLength(publicData?.sizes);
  const isRentalPage = isRentalSearchPage();

  return (
    <div
      className={classes}
      onClick={() =>
        typeof window !== 'undefined' && window.sessionStorage.setItem('saveScroll', 'true')
      }
    >
      <div className={css.cardTopInfo}>
        <div className={css.authorBlock}>
          <span className={css.richText}>
            {richText(showLimitedText(title, 24), {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </span>
        </div>
        <div className={css.cardTopInfoLeft}>
          {!isOwnListing ? (
            <div className={css.favorite}>
              <span
                className={css.bookmark}
                onClick={e => {
                  if (isAuthenticated) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (id) {
                      const index = bookmarks && bookmarks.findIndex(b => b == id);
                      if (index > -1) {
                        bookmarks && bookmarks.splice(index, 1);
                        onUpdateCurrentUser(bookmarks);
                        dispatch(
                          removeStoreProductMailChimp({
                            productId: id,
                            currentUser,
                            isSyncedWithMC,
                          })
                        );
                      } else {
                        bookmarks.push(id);
                        onUpdateCurrentUser(Array.from(new Set(bookmarks)));
                        dispatch(
                          addStoreProductMailChimp({ productParams, currentUser, isSyncedWithMC })
                        );
                      }
                    }
                  } else {
                    const localIndex = localBookmarks && localBookmarks.findIndex(b => b == id);
                    if (localIndex > -1) {
                      localBookmarks && localBookmarks.splice(localIndex, 1);
                      const removedBookmarks = Array.from(new Set(localBookmarks));
                      isWindowDefined() &&
                        window.localStorage.setItem(
                          'localBookmarks',
                          JSON.stringify(removedBookmarks)
                        );
                      setCartItems(removedBookmarks);
                    } else {
                      localBookmarks.push(id);
                      const addedBookmarks = Array.from(new Set(localBookmarks));
                      isWindowDefined() &&
                        window.localStorage.setItem(
                          'localBookmarks',
                          JSON.stringify(addedBookmarks)
                        );
                      setCartItems(addedBookmarks);
                    }
                  }
                }}
              >
                {bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
                  <IconBagFilled />
                ) : (
                  <IconBag />
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className={css.cardImg}>
        <ImageSlider
          images={images}
          isMobile={isMobile}
          imgAlt={imgAlt}
          pathParams={{ id, slug }}
          pushToListingPage={() => {
            history.push(
              createResourceLocatorString('ListingPage', routeConfiguration(), { id, slug }, {})
            );
            onUpdateRecentlyViewed();
          }}
        />
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.authorInfo}>
            <span className={css.productPrice}>${productPrice && productPrice.toFixed(2)}</span>
          </div>
          <div className={css.price}>
            {!isOwnListing ? (
              <div className={css.saves}>
                <span
                  className={css.bookmark}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!isAuthenticated && e && id) {
                      const routes = routeConfiguration();
                      setLocalStorageItems([id], 'wishlist');
                      setLocalStorageItems(likes, 'currentUserLikes');
                      history.push(createResourceLocatorString('LoginPage', routes, {}, {}), {
                        from: `/favourites`,
                      });
                    } else {
                      const index = wishlist && wishlist.findIndex(b => b == id);
                      const likesIndex =
                        Array.isArray(likes) && likes.findIndex(l => l == currentUser?.id?.uuid);

                      if (id) {
                        // To save in listing to show likes
                        if (likesIndex > -1) {
                          likes && likes.splice(likesIndex, 1);
                          const filteredLikes = Array.from(new Set(likes));
                          setCacheCount(filteredLikes?.length);
                          dispatch(updateSave({ likes: filteredLikes, listingId: id }));
                        } else {
                          Array.isArray(likes) && likes.push(currentUser?.id?.uuid);
                          const filteredLikes = Array.from(new Set(likes));
                          setCacheCount(filteredLikes?.length);
                          dispatch(updateSave({ likes: filteredLikes, listingId: id }));
                        }

                        // To save in currentUser as favourites
                        if (index > -1) {
                          wishlist && wishlist.splice(index, 1);
                          const removedwishlist = Array.from(new Set(wishlist));
                          onUpdateCurrentUser(removedwishlist, true);
                        } else {
                          wishlist.push(id);
                          onUpdateCurrentUser(Array.from(new Set(wishlist)), true);
                        }
                      }
                    }
                  }}
                >
                  {likes && likes.findIndex(e => e == currentUser?.id?.uuid) > -1 ? (
                    <IconHeartFilled />
                  ) : (
                    <IconHeart />
                  )}{' '}
                  {cacheCount || likesCount}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className={css.authorInfo}>
          Brand:{' '}
          {publicData &&
            publicData.brand &&
            getBrandName(
              publicData.brand,
              config.custom.brandOptions.concat(config.custom.shoesBrandOptions)
            )}
        </div>
        <div className={css.authorInfo}>Condition: {publicData && publicData.condition}</div>
        <div className={css.authorInfo}>
          {sizeText} {hasVariants ? 'Mulitple sizes available' : publicData?.size}
        </div>
      </div>
    </div>
  );
};

ListingCardMobileComponent.defaultProps = {
  className: null,
  rootClassName: null,
  setActiveListing: null,
  showAuthorInfo: true,
  brandText: 'Brand:',
  conditionText: 'Condition:',
  sizeText: 'Size:',
};

ListingCardMobileComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  setActiveListing: func,
};

export default injectIntl(ListingCardMobileComponent);
