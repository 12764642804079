export const ACCOUNT_PAGE = '/account';
export const BOOKING_PROCESS = 'booking-process';
export const COSTUME_SEARCH_PAGE = 'CostumeSearchPage';
export const BASE_SEARCH_PAGE = 'SearchPage';
export const COSTUMES = 'costumes';
export const DASHBOARD_BACK_DATE = 1677770411;
export const VISHAL = 14021997;
export const ERROR = 'ERROR';
export const FREE = 'free';
export const FREE_SHIPPING = 'Free Shipping';
export const GENERAL_SEO =
  'dancewear, ballet, ballet shoes, tutu, tights, costumes, rubiawear, yumiko, luckyleo, chameleon activewear, dance, leotard, five dancewear, balera, fivedancewear, jule dancewear, mariia dancewear, kandi kouture, russian pointe shoes, leg warmers, 5 dancewear, dancewear solutions, dance apparel, dance costumes, ballet tutus, dance accessories, ballet attire, dance gear, dancewear store, ballet clothing, dancewear online, dance clothing, ballerina outfits, dance outfits, ballet leotards, dance shoes, dancewear brands, dancewear shop, dance supply, dance fashion, ballet dresses, dancewear for kids, dancewear for adults, dancewear sale, ballet attire for sale, dancewear boutique, dancewear for ballet, affordable dancewear, ballet costumes for sale, discount dancewear, quality dancewear, dancewear for professionals';
export const LIKE_NEW = 'Like-New';
export const MARK_RECEIVED = 'Mark received';
export const TUTULIST_PICKUP = 'TUTULISTPICKUP';
export const NO_IMAGE =
  'https://vsc-test.s3.ap-south-1.amazonaws.com/f2ec181f-af92-4f78-8f08-b87f67998f54.png';
export const OWN = 'own';
export const PAYPAL = 'paypal';
export const PAYPAL_MERCHANT_PAGE = '/account/paypal-merchant';
export const IS_COSTUMES_RENTALS = '/costumes-rentals';
export const SHIPPING = 'shipping';
export const SHIPPING_PREFERENCE = 'shippingPreference';
export const RENTAL_DISCLAIMER =
  'Please note that while we stand by the quality of our products, customers are responsible for any damages incurred post-delivery. In cases of light damage, a nominal fee will be assessed. However, for heavily damaged products, additional charges may apply. These fees are determined on a case-by-case basis, depending on the extent of the damage and the cost of repair or replacement. By completing your purchase, you acknowledge and agree to this policy.';
export const STRING = 'string';
export const STRIPE = 'stripe';
export const SUCCESS = 'SUCCESS';
export const OFFER_PROCESS = 'offer-process';
export const TUTULIST_USER_ID = '62f6cbdb-5201-44ab-9b62-b2c234b566ec';
export const TUTULIST = 'Tutulist';
export const TUTULIST_LOWERCASE = 'tutulist';
export const TRACKING_DATE_FORMAT = 'Do MMMM YYYY, h:mm a';
export const YES = 'yes';
export const COLOR = 'color';

export const SELLING = 'selling';
export const SOLD = 'sold';
export const LIKES = 'likes';
export const REVIEWS = 'reviews';
export const RENTALS = 'rentals';
export const storePageTabs = [SELLING, SOLD, LIKES, REVIEWS];
export const storePageRentalsTabs = [SELLING, RENTALS, SOLD, LIKES, REVIEWS];
export const SHOPIFY_TRANSACTIONS_COUNT = 1256;

export const PASSWORD = 'password';
export const TEXT = 'text';

// These tabs are being used in checkout page for TTL shipping
export const shippingTabs = [
  {
    key: 'shippingDetails',
    label: 'Your Information',
  },
  {
    key: 'shippingMethod',
    label: 'Shipping Method',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];

// These tabs are being used in checkout page for own shipping
export const ownShippingTabs = [
  {
    key: 'shippingDetails',
    label: 'Your Information',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];

// These tabs are being used in checkout page for just payment without shipping
export const paymentTabs = [
  {
    key: 'shippingDetails',
    label: 'Your Information',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];

export const RESPONSE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const CART_TYPE = {
  ADD: 'add',
  REMOVE: 'remove',
  ERROR: 'ERROR',
};

export const FEATURED_BRAND = ['Eleve', 'Yumiko', 'LuckyLeo', 'Five-Dancewear', 'Tiger-Friday'];

export const listingCardRenderSizes = [
  '(max-width: 549px) 100vw',
  '(max-width: 767px) 50vw',
  `(max-width: 1439px) 26vw`,
  `(max-width: 1920px) 18vw`,
  `14vw`,
].join(', ');

export const LISTING_QUERY_PARAMS = {
  include: ['author', 'author.bio', 'author.profileImage', 'images', 'currentStock'],
  'fields.listing': ['title', 'description', 'price', 'publicData'],
  'fields.user': [
    'profile.displayName',
    'profile.abbreviatedName',
    'profile.publicData',
    'profile.bio',
  ],
  'fields.image': [
    `variants.listing-card`,
    `variants.listing-card-2x`,
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
};
