import React from 'react';

function IconMessage() {
  return (
    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.45455C9.73557 5.45455 6.65128 5.64867 4.69268 5.79141C4.04222 5.83882 3.53846 6.35372 3.53846 6.98262V7.69328L11.3774 11.8107C11.7646 12.0141 12.2354 12.0141 12.6226 11.8107L20.4615 7.69328V6.98261C20.4615 6.35372 19.9578 5.83882 19.3073 5.79141C17.3487 5.64867 14.2644 5.45455 12 5.45455ZM20.4615 9.35722L13.3698 13.0822C12.5179 13.5296 11.4821 13.5296 10.6302 13.0822L3.53846 9.35722V17.0174C3.53846 17.6463 4.04222 18.1612 4.69268 18.2086C6.65128 18.3513 9.73557 18.5455 12 18.5455C14.2644 18.5455 17.3487 18.3513 19.3073 18.2086C19.9578 18.1612 20.4615 17.6463 20.4615 17.0174V9.35722ZM4.57444 4.34117C6.53421 4.19834 9.67068 4 12 4C14.3293 4 17.4658 4.19834 19.4256 4.34117C20.8902 4.44791 22 5.60588 22 6.98261V17.0174C22 18.3941 20.8902 19.5521 19.4256 19.6588C17.4658 19.8017 14.3293 20 12 20C9.67068 20 6.53421 19.8017 4.57444 19.6588C3.10982 19.5521 2 18.3941 2 17.0174V6.98262C2 5.60589 3.10982 4.44791 4.57444 4.34117Z"
        fill="#4a4a4a"
      />
    </svg>
  );
}

export default IconMessage;
