import { addProduct, removeProduct } from '../util/api';
import { isArrayLength } from '../util/dataExtractors';
import { NO_IMAGE } from '../util/enums';

// ================ Action types ================ //

// export const ONBOARDING_LINK_REQUEST = 'app/global/ONBOARDING_LINK_REQUEST';

// ================ Reducer ================ //

const initialState = {
  // fetchOnboardingLinkInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // case ONBOARDING_LINK_REQUEST:
    //   return { ...state, fetchOnboardingLinkError: null, fetchOnboardingLinkInProgress: true };
    default:
      return state;
  }
}

// ================ Action creators ================ //

// export const onboardingLinkRequest = () => ({ type: ONBOARDING_LINK_REQUEST });

// ================ Thunks ================ //

export const addStoreProductMailChimp = params => async (dispatch, getState, sdk) => {
  try {
    const response = await addProduct({ ...params });
    // console.log(response, 'response');
  } catch (error) {
    console.log(error, `Error while adding store product to mailchimp`);
  }
};

export const removeStoreProductMailChimp = params => async (dispatch, getState, sdk) => {
  try {
    const response = await removeProduct({ ...params });
    // console.log(response, 'response');
  } catch (error) {
    console.log(error, `Error while removing store product to mailchimp`);
  }
};

export const uploadImageViaSharetribeSDK = file => async (dispatch, getState, sdk) => {
  function extractImageUrl(data) {
    if (data?.data?.data?.attributes?.variants) {
      return data.data.data.attributes.variants.default.url;
    }
    return NO_IMAGE; // Return null if the necessary data is not found
  }

  try {
    const response = await sdk.images.upload(
      {
        image: file,
      },
      {
        expand: true,
      }
    );
    return extractImageUrl(response);
  } catch (error) {
    console.log(error, `Error while removing store product to mailchimp`);
  }
};

export const getValidCartIds = bookmarks => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.query({
      minStock: 1,
      ids: bookmarks,
    });
    const listings = response?.data?.data;
    return isArrayLength(listings) ? listings.map(l => l?.id?.uuid) : [];
  } catch (error) {
    return [];
  }
};
