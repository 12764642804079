import React, { forwardRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FieldTextInput, Form, IconSearch } from '..';

import css from './CustomSearchForm.module.css';

const CustomSearchForm = forwardRef((props, ref) => {
  return (
    // this is the main coponent that surrounds our complete state
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          intl,
          handleSubmit,
          placeholder = 'Search',
        } = formRenderProps;

        const classes = classNames(
          rootClassName,
          className,
          props.isErrorPageSearch ? css.errorPageSearch : null,
          props.isLandingPage ? css.landingHeroSearch : null
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.searchItemsContainer}>
              <div className={css.keywordSearch}>
                {props.isErrorPageSearch ? <IconSearch /> : null}
                <FieldTextInput
                  id="keywords"
                  name="keywords"
                  className={css.field}
                  type="text"
                  placeholder={placeholder}
                  isUncontrolled
                  inputRef={ref}
                />
              </div>
              <button className={css.button} type="submit">
                {props.isErrorPageSearch || props.isLandingPage ? 'Search' : <IconSearch />}
              </button>
            </div>
            {/* {props.isLandingPage && (
              <div className={css.searchKeywords}>
                <span className={css.pills}>Leotards</span>
                <span className={css.pills}>Warmups</span>
                <span className={css.pills}>Shoes</span>
              </div>
            )} */}
          </Form>
        );
      }}
    />
  );
});

export default CustomSearchForm;
