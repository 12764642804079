import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  DASHBOARD_TRANSITIONS,
  TRANSITIONS,
  TRANSITION_CONFIRM_PAYMENT,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createPayout, queryTransaction, showStripeAccount } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_ERROR';

export const WITHDRAWAL_REQUEST = 'app/DashboardPage/WITHDRAWAL_REQUEST';
export const WITHDRAWAL_SUCCESS = 'app/DashboardPage/WITHDRAWAL_SUCCESS';
export const WITHDRAWAL_ERROR = 'app/DashboardPage/WITHDRAWAL_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactions: [],
  withdrawalRequested: false,
  withdrawalError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case WITHDRAWAL_REQUEST:
      return { ...state, withdrawalRequested: true, withdrawalError: null };
    case WITHDRAWAL_SUCCESS:
      return { ...state, withdrawalRequested: false, withdrawalError: null };
    case WITHDRAWAL_ERROR:
      return { ...state, withdrawalRequested: false, withdrawalError: payload };
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        transactions: payload,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const withdrawalRequest = () => ({ type: WITHDRAWAL_REQUEST });
const withdrawalSuccess = () => ({ type: WITHDRAWAL_SUCCESS });
const withdrawalError = e => ({
  type: WITHDRAWAL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const allTransactions = [];

export const loadData = params => async (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  await dispatch(fetchCurrentUser());

  const currentUser = getState().user.currentUser;

  try {
  } catch (error) {}

  const extraParams = {
    only: 'sale',
    lastTransitions: DASHBOARD_TRANSITIONS.concat(TRANSITION_CONFIRM_PAYMENT),
  };

  const queryNextTransactions = async (page, totalPages) => {
    const newPage = page + 1;
    const {
      data: { meta = {}, data: txs = [] },
    } = await sdk.transactions.query({
      page: newPage,
      ...extraParams,
    });
    const { totalItems = 0 } = meta;

    allTransactions.push(...txs);

    if (totalItems > 0 && totalPages !== newPage) {
      await queryNextTransactions(newPage, totalPages);
    }
  };

  const fetchTransactions = async () => {
    const firstQueryResponse = await sdk.transactions.query({
      ...extraParams,
    });
    const meta = firstQueryResponse?.data?.meta;
    const page = meta?.page;
    const totalPages = meta?.totalPages;
    const totalItems = meta?.totalItems;
    const firstResponse = firstQueryResponse?.data?.data || [];
    allTransactions.push(...firstResponse);

    if (totalItems > 0 && totalPages !== page) {
      await queryNextTransactions(page, totalPages);
    }
  };

  if (currentUser?.id?.uuid) {
    await Promise.all([queryTransaction({ userId: currentUser?.id?.uuid }), fetchTransactions()]);
  }

  dispatch(fetchOrdersOrSalesSuccess(allTransactions));
};

export const withdrawal = params => async (dispatch, getState, sdk) => {
  dispatch(withdrawalRequest());
  try {
    const status = await createPayout({ ...params });
    return (
      status &&
      Promise.all([dispatch(withdrawalSuccess())])
        .then(() => {
          dispatch(fetchCurrentUser());
          return status;
        })
        .catch(e => {
          console.log(e, `error while creating withdrawal for ${params?.providerId}`);
          return e;
        })
    );
  } catch (error) {
    console.log(error, `error while creating withdrawal for ${params?.providerId}`);
    dispatch(withdrawalError());
    return error;
  }
};

export const getStripeAccount = params => async (dispatch, getState, sdk) => {
  try {
    const stripeResponse = await showStripeAccount({ ...params });
    return stripeResponse?.response;
  } catch (error) {
    return error;
  }
};
