/**
 * This is a wrapper component for different Layouts. Main content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './LayoutWrapperMain.module.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import GlobalBottomNavMobile from '../GlobalBottomNavMobile/GlobalBottomNavMobile';

const LayoutWrapperMainComponent = props => {
  const { className, rootClassName, children } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes} role="main">
      {children}
      <GlobalBottomNavMobile />
    </div>
  );
};

LayoutWrapperMainComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { node, string } = PropTypes;

LayoutWrapperMainComponent.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
};

const mapStateToProps = state => {
  const { currentUserHasListings, currentUser } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    currentUser,
    currentUserHasListings,
    isAuthenticated,
  };
};

const LayoutWrapperMain = compose(connect(mapStateToProps, null))(LayoutWrapperMainComponent);

export default LayoutWrapperMain;
