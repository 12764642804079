import React from 'react';

function IconBagFilled() {
  return (
    <svg width="24" height="24" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 5H29L26 18H8M26 22H9L4 1H1"
        stroke="#c6488c"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: 'none' }}
      />
      <path
        d="M24 28C25.1046 28 26 27.1046 26 26C26 24.8954 25.1046 24 24 24C22.8954 24 22 24.8954 22 26C22 27.1046 22.8954 28 24 28Z"
        stroke="#c6488c"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: 'none' }}
      />
      <path
        d="M11 28C12.1046 28 13 27.1046 13 26C13 24.8954 12.1046 24 11 24C9.89543 24 9 24.8954 9 26C9 27.1046 9.89543 28 11 28Z"
        stroke="#c6488c"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: 'none' }}
      />
      <path d="M5.13462 5H29L26 18.5L8 18L5.13462 5Z" fill="#c6488c" />
    </svg>
  );
}

export default IconBagFilled;
