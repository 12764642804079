import uuid from 'react-uuid';
import { profileFilter } from '../config/enums';
import { isArrayLength } from './dataExtractors';

export const category = [profileFilter.DANCEWEAR, profileFilter.SHOES];

export const generateUsername = firstName => {
  const random = uuid().split('-')[1];
  return firstName + random;
};

export const isValidUsername = str => {
  var error = '';
  var illegalChars = /\W/; // allow letters, numbers, and underscores
  if (str == '') {
    error = 'Please enter Username';
  } else if (str.length < 5 || str.length > 20) {
    error = 'Username must have 5-20 characters';
  } else if (illegalChars.test(str)) {
    error = 'Please enter valid Username. Use only numbers and alphabets';
  } else {
    error = '';
  }
  return error;
};

export const filterListings = (filterBy, listings, type) => {
  return (
    listings.filter(
      item => item?.attributes?.publicData && item?.attributes?.publicData[type] === filterBy
    ) || []
  );
};

export const getFilteredListings = (filterBy, listings) => {
  if (category.includes(filterBy)) {
    const filteredListings =
      isArrayLength(listings) && filterListings(filterBy, listings, profileFilter.CATEGORY);
    return filteredListings;
  } else if (filterBy === profileFilter.SHIPPING) {
    const filteredListings =
      (isArrayLength(listings) &&
        listings.filter(
          item =>
            item?.attributes?.publicData && item?.attributes?.publicData.shippingEnabled === true
        )) ||
      [];
    return filteredListings;
  } else if (filterBy === profileFilter.FREE_SHIPPING) {
    const filteredListings =
      (isArrayLength(listings) &&
        listings.filter(
          item =>
            item?.attributes?.publicData && item?.attributes?.publicData.shippingEnabled === false
        )) ||
      [];
    return filteredListings;
  } else if (filterBy === profileFilter.NEW) {
    const filteredListings =
      (isArrayLength(listings) &&
        listings.filter(
          item =>
            item?.attributes?.publicData &&
            item?.attributes?.publicData.condition === filterBy.toUpperCase()
        )) ||
      [];
    return filteredListings;
  }
};
