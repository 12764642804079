import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchPaypalMerchantSignupStatus } from '../../ducks/paypal.duck';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StripePayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const SAVE_MERCHANT_REQUEST = 'app/StripePayoutPage/SAVE_MERCHANT_REQUEST';
export const SAVE_MERCHANT_SUCCESS = 'app/StripePayoutPage/SAVE_MERCHANT_SUCCESS';
export const SAVE_MERCHANT_ERROR = 'app/StripePayoutPage/SAVE_MERCHANT_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  merchantSaveInProgress: false,
  merchantSaved: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
    case SAVE_MERCHANT_REQUEST:
      return { ...state, merchantSaveInProgress: true };
    case SAVE_MERCHANT_ERROR:
      return { ...state, merchantSaveInProgress: false };
    case SAVE_MERCHANT_SUCCESS:
      return { ...state, merchantSaveInProgress: false, merchantSaved: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

export const saveMerchantRequest = () => ({
  type: SAVE_MERCHANT_REQUEST,
});
export const saveMerchantError = error => ({
  type: SAVE_MERCHANT_ERROR,
  payload: error,
  error: true,
});
export const saveMerchantSuccess = () => ({
  type: SAVE_MERCHANT_SUCCESS,
});

// ================ Thunks ================ //

const requestSaveMerchant = merchantId => (dispatch, getState, sdk) => {
  dispatch(saveMerchantRequest());
  return sdk.currentUser
    .updateProfile({ publicData: { merchantId } }, { expand: true })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveMerchantRequest());
    })
    .catch(e => {
      dispatch(saveMerchantError(storableError(e)));
    });
};

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const { merchantId: trackingId, merchantIdInPayPal } = queryParams;

  const isOnboardingReturn =
    params?.returnURLType === 'onboardingResult' && trackingId && merchantIdInPayPal;
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.stripeAccount) {
      dispatch(fetchStripeAccount());
    }

    const shouldSaveMerchant =
      isOnboardingReturn && currentUser && currentUser.id.uuid === trackingId;
    if (shouldSaveMerchant) {
      dispatch(requestSaveMerchant(merchantIdInPayPal));
    }
    if (currentUser && currentUser.attributes.profile.publicData?.merchantId) {
      dispatch(fetchPaypalMerchantSignupStatus());
    }
    return response;
  });
};
