import React from 'react';
import ContentLoader from 'react-content-loader';

const SingleLoader = props => (
  <ContentLoader
    speed={2}
    width={327}
    height={27}
    viewBox="0 0 327 27"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="327" height="27" />
  </ContentLoader>
);

export default SingleLoader;
