import React from 'react';
import Carousel from 'react-multi-carousel';
import '../../styles/react-multi-carousel/React-Multi-Carousel.css';
import IconPrevArrow from '../IconPrevArrow/IconPrevArrow';
import NamedLink from '../NamedLink/NamedLink';
import IconNextArrow from '../IconNextArrow/IconNextArrow';
import SellerShopCard from '../SellerShopCard/SellerShopCard';
import css from './ContentSlider.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const ButtonGroup = props => {
  const { goToSlide, carouselState, showExploreNow } = props;
  const currentSlide = (carouselState && carouselState.currentSlide) || 1;
  return (
    <div className={css.sliderButtons}>
      {showExploreNow ? (
        <NamedLink className={css.exploreBtn} name="SellerSearchPage">
          Explore Now
        </NamedLink>
      ) : null}
      <span
        className={css.iconHolder}
        onClick={() => {
          goToSlide(currentSlide - 1);
        }}
      >
        <IconPrevArrow className={css.icon} />
      </span>
      <span
        className={css.iconHolder}
        onClick={() => {
          goToSlide(currentSlide + 1);
        }}
      >
        <IconNextArrow className={css.icon} />
      </span>
    </div>
  );
};

function ContentSlider(props) {
  const { data = [], showExploreNow = false } = props;

  if (!data?.length) {
    return <></>;
  }

  return (
    <div className={css.sliderContainer}>
      <Carousel
        swipeable={true}
        draggable={false}
        ssr={true}
        infinite={true}
        focusOnSelect={true}
        keyBoardControl={true}
        renderDotsOutside={true}
        responsive={responsive}
        showDots={false}
        customButtonGroup={<ButtonGroup showExploreNow={showExploreNow} />}
        removeArrowOnDeviceType={['tablet', 'mobile']}
      >
        {data && data?.length
          ? data.map((item, index) => <SellerShopCard key={index} shop={item} />)
          : null}
      </Carousel>
    </div>
  );
}

export default ContentSlider;
