import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';
import InfoPopup from '../InfoPopup/InfoPopup';
import { isArrayLength } from '../../util/dataExtractors';

const { Money } = sdkTypes;

const LineItemShippingFeeMaybe = props => {
  const {
    lineItems,
    intl,
    shippingFlowMessage = false,
    isRentals = false,
    isDefaultShippingFlow = false,
    shippingInfo = null,
    // isCheckoutPage,
    // isCustomer,
  } = props;

  const shippingFeeLineItem =
    isArrayLength(lineItems) && lineItems.filter(item => item.code === LINE_ITEM_SHIPPING_FEE);
  const shippingPricesSum =
    isArrayLength(shippingFeeLineItem) &&
    shippingFeeLineItem
      .map(item => item.lineTotal)
      .reduce(function(acc, obj) {
        return acc + obj.amount;
      }, 0);

  const totalShipping = shippingPricesSum
    ? formatMoney(intl, new Money(shippingPricesSum, 'USD'))
    : isRentals
    ? '(Calculated on next step)'
    : 'Free shipping';

  return shippingFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {shippingInfo ? (
          <FormattedMessage
            id="OrderBreakdown.selectedCarrier"
            values={{ carrier: shippingInfo?.name || '' }}
          />
        ) : (
          <FormattedMessage id="OrderBreakdown.shippingFee" />
        )}
        {/* {!isCheckoutPage && !isCustomer ? (
          <InfoPopup info="You are being charged this fee because you have selected to cover the cost of shipping and offer free shipping to buyers." />
        ) : null} */}
      </span>
      <span className={css.itemValue}>
        {isDefaultShippingFlow ? totalShipping : shippingFlowMessage()}
      </span>
    </div>
  ) : null;
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
