import React from 'react';

function IconHeartFilled() {
  return (
    <>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 491 437"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M222.5 426.7C228.6 432.8 236.8 436.2 245.4 436.2C253.9 436.2 262.3 432.7 268.3 426.7L448 247C475.3 219.7 490.3 183.4 490.4 144.9C490.4 106.3 475.4 70 448.1 42.7C420.8 15.4 384.6 0.400011 346 0.400011C308.1 0.400011 272.4 14.9 245.3 41.3C218.1 14.8 182.3 0.200012 144.3 0.200012C105.8 0.200012 69.6 15.2 42.3 42.4C15 69.7 0 106 0 144.6C0 183.1 15.1 219.4 42.4 246.7L222.5 426.7Z"
          fill="#c6488c"
        />
      </svg>
    </>
  );
}

export default IconHeartFilled;
