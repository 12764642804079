import React from 'react';
import ContentLoader from 'react-content-loader';
import css from './ListSkeleton.module.css';

const ListSkeleton = props => {
  const { viewport } = props;
  const MAX_MOBILE_SCREEN_WIDTH = 768;
  const MIN_MOBILE_SCREEN_WIDTH = 480;
  const [isMobileViewLoader, setisMobileViewLoader] = React.useState(
    viewport.width >= MAX_MOBILE_SCREEN_WIDTH
  );
  const [isXMobileViewLoader, setisXMobileViewLoader] = React.useState(
    viewport.width >= MIN_MOBILE_SCREEN_WIDTH
  );

  React.useEffect(() => {
    setisMobileViewLoader(viewport.width >= MAX_MOBILE_SCREEN_WIDTH);
    setisXMobileViewLoader(viewport.width >= MIN_MOBILE_SCREEN_WIDTH);
  }, [viewport]);

  const getLoader = () => {
    if (isMobileViewLoader) {
      return (
        <ContentLoader
          speed={2}
          width={700}
          height={270}
          viewBox="0 0 700 270"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="8" y="215" rx="7" ry="7" width="188" height="16" />
          <rect x="9" y="245" rx="7" ry="7" width="127" height="16" />
          <rect x="9" y="12" rx="0" ry="0" width="188" height="188" />
          <rect x="230" y="213" rx="7" ry="7" width="188" height="16" />
          <rect x="231" y="243" rx="7" ry="7" width="127" height="16" />
          <rect x="231" y="10" rx="0" ry="0" width="188" height="188" />
          <rect x="454" y="215" rx="7" ry="7" width="188" height="16" />
          <rect x="453" y="245" rx="7" ry="7" width="127" height="16" />
          <rect x="456" y="10" rx="0" ry="0" width="188" height="188" />
        </ContentLoader>
      );
    } else if (isXMobileViewLoader) {
      return (
        <ContentLoader
          speed={2}
          width={450}
          height={170}
          viewBox="0 0 450 170"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="10" y="137" rx="7" ry="7" width="122" height="10" />
          <rect x="11" y="9" rx="0" ry="0" width="120" height="120" />
          <rect x="11" y="153" rx="7" ry="7" width="84" height="7" />
          <rect x="152" y="136" rx="7" ry="7" width="122" height="10" />
          <rect x="153" y="8" rx="0" ry="0" width="120" height="120" />
          <rect x="153" y="152" rx="7" ry="7" width="84" height="7" />
          <rect x="293" y="136" rx="7" ry="7" width="122" height="10" />
          <rect x="294" y="8" rx="0" ry="0" width="120" height="120" />
          <rect x="294" y="152" rx="7" ry="7" width="84" height="7" />
        </ContentLoader>
      );
    } else {
      return (
        <ContentLoader
          speed={2}
          width={280}
          height={160}
          viewBox="0 0 280 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="10" y="137" rx="7" ry="7" width="122" height="10" />
          <rect x="11" y="9" rx="0" ry="0" width="120" height="120" />
          <rect x="11" y="153" rx="7" ry="7" width="84" height="7" />
          <rect x="152" y="136" rx="7" ry="7" width="122" height="10" />
          <rect x="153" y="8" rx="0" ry="0" width="120" height="120" />
          <rect x="153" y="152" rx="7" ry="7" width="84" height="7" />
        </ContentLoader>
      );
    }
  };
  return <div className={css.listSkeletonSec}>{getLoader()}</div>;
};

export default ListSkeleton;
