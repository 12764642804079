/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';
import { createResourceLocatorString } from '../../../util/routes';
import Drawer from 'react-modern-drawer';
import IconArrowRight from '../../IconArrowRight/IconArrowRight';

import css from './TopbarMobileMenu.module.css';
import './Drawer.css';
import IconSocialMediaTiktok from '../../IconSocialMediaTiktok/IconSocialMediaTiktok';
import IconSocialMediaInstagram from '../../IconSocialMediaInstagram/IconSocialMediaInstagram';
import IconSocialMediaFacebook from '../../IconSocialMediaFacebook/IconSocialMediaFacebook';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    // currentPage,
    currentUserHasListings,
    currentUser,
    // notificationCount,
    onLogout,
    history,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const user = ensureCurrentUser(currentUser);

  const handleRedirect = key => {
    const isWarmup = key && key == 'warmups';
    const isLeo = key && key == 'leotards';

    const warmupSearchParams = {
      pub_type: 'Warmups',
      pub_category: 'dancewear',
    };

    const leoSearchParams = {
      pub_type: 'Leotard',
      pub_category: 'dancewear',
    };

    const searchParams = {
      pub_category: key,
    };
    const params = isWarmup ? warmupSearchParams : isLeo ? leoSearchParams : searchParams;
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, params));
  };

  const handleBrandsRedirect = (key, page = 'SearchPage', searchKey = 'pub_brand') => {
    const searchParams = key
      ? {
          [searchKey]: key,
        }
      : {};

    history.push(createResourceLocatorString(page, routeConfiguration(), {}, searchParams));
  };

  const renderBrands = (
    <>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span onClick={() => handleBrandsRedirect('Eleve', 'SearchPage')}>
            Eleve <IconArrowRight />
          </span>
        </div>
      </div>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span onClick={() => handleBrandsRedirect('Five-Dancewear', 'SearchPage')}>
            Five-Dancewear <IconArrowRight />
          </span>
        </div>
      </div>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span onClick={() => handleBrandsRedirect('LuckyLeo', 'SearchPage')}>
            LuckyLeo <IconArrowRight />
          </span>
        </div>
      </div>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span onClick={() => handleBrandsRedirect('Tiger-Friday', 'SearchPage')}>
            Tiger-Friday <IconArrowRight />
          </span>
        </div>
      </div>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span onClick={() => handleBrandsRedirect('Yumiko', 'SearchPage')}>
            Yumiko <IconArrowRight />
          </span>
        </div>
      </div>
    </>
  );

  const renderCostumes = (
    <>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span onClick={() => handleBrandsRedirect(null, 'CostumeSearchPage')}>
            All Costumes <IconArrowRight />
          </span>
        </div>
      </div>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span
            onClick={() =>
              handleBrandsRedirect('Individual', 'CostumeSearchPage', 'pub_listingType')
            }
          >
            Single Costumes <IconArrowRight />
          </span>
        </div>
      </div>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span
            onClick={() => handleBrandsRedirect('Classical-Tutu', 'CostumeSearchPage', 'pub_type')}
          >
            Classical Tutus <IconArrowRight />
          </span>
        </div>
      </div>
      <div className={css.links}>
        <div className={css.linkItem}>
          <span onClick={() => handleBrandsRedirect('Lot', 'CostumeSearchPage', 'pub_listingType')}>
            Costume Lots <IconArrowRight />
          </span>
        </div>
      </div>
    </>
  );

  const drawer = (
    <Drawer open={isOpen} onClose={() => setIsOpen(false)} direction="left">
      <div className={classNames(css.browseMenu, css.drawerMenu)}>
        <div className={css.drawerHead}>
          <span className={css.back} onClick={() => setIsOpen(false)}>
            <IconArrowRight />
          </span>
          <span className={css.cross} onClick={() => setIsOpen(false)}>
            &times;
          </span>
        </div>
        {isOpen === 'brand' ? renderBrands : renderCostumes}
      </div>
    </Drawer>
  );

  const drawerData = (
    <>
      <div className={css.browseMenu}>
        <h2>Browse Tutulist</h2>
        {drawer}
        <div className={css.links}>
          <div className={css.linkItem}>
            <span onClick={() => handleRedirect('dancewear')}>
              All Dancewear <IconArrowRight />
            </span>
          </div>
          <div className={css.linkItem}>
            <span onClick={() => handleRedirect('leotards')}>
              Leotards <IconArrowRight />
            </span>
          </div>
          <div className={css.linkItem}>
            <span onClick={() => handleRedirect('warmups')}>
              Warmups <IconArrowRight />
            </span>
          </div>
          <div className={css.linkItem}>
            <span onClick={() => handleRedirect('shoes')}>
              Shoes <IconArrowRight />
            </span>
          </div>
          <div className={css.linkItem}>
            <span onClick={() => setIsOpen('costumes')}>
              Costumes <IconArrowRight />
            </span>
          </div>
          <div className={css.linkItem}>
            <span onClick={() => setIsOpen('brand')}>
              Top Brands <IconArrowRight />
            </span>
          </div>
        </div>
      </div>
      <div className={css.moreFrom}>
        <h2>More from Tutulist</h2>
        <div className={css.links}>
          <div className={css.linkItem}>
            <NamedLink name="NewListingPage" className={css.link}>
              List an Item
            </NamedLink>
          </div>
          <div className={css.linkItem}>
            <a className={css.link} href="/p/selltotutulist">
              Ways to Sell
            </a>
          </div>
          <div className={css.linkItem}>
            <a className={css.link} href="/p/encore-envelope">
              Encore Envelopes
            </a>
          </div>
          <div className={css.linkItem}>
            <a
              className={css.link}
              href="https://support.tutulist.com/hc/en-us/categories/10436045187213-Sell"
            >
              Seller FAQ
            </a>
          </div>
          <div className={css.linkItem}>
            <a
              className={css.link}
              href="https://support.tutulist.com/hc/en-us/categories/10436054867853-Buy"
            >
              Buyer FAQ
            </a>
          </div>
          <div className={css.linkItem}>
            <a className={css.link} href="/p/about">
              About Us
            </a>
          </div>
          <div className={css.linkItem}>
            <a className={css.link} href="https://support.tutulist.com/hc/en-us">
              Help Center
            </a>
          </div>
          <div className={css.linkItem}>
            <a className={css.link} href="/contact">
              <FormattedMessage id="Footer.toContactPage" />
            </a>
          </div>
          <div className={css.linkItem}>
            <a
              className={css.link}
              href="https://support.tutulist.com/hc/en-us/articles/13914185704077-Safety-and-Community-on-Tutulist"
            >
              Community Guidelines
            </a>
          </div>
          <div className={css.linkItem}>
            <a className={css.link} href="/p/terms-of-service">
              <FormattedMessage id="Footer.termsOfUse" />
            </a>
          </div>
          <div className={css.linkItem}>
            <a className={css.link} href="/p/privacy-policy">
              <FormattedMessage id="Footer.privacyPolicy" />
            </a>
          </div>
        </div>
      </div>
    </>
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    // const signupOrLogin = (
    //   <span className={css.authenticationLinks}>
    //     <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
    //   </span>
    // );

    return (
      <div className={css.root}>
        <p className={css.community}>Join our Community</p>
        <div className={css.socialMediaLinks}>
          <a href="https://www.facebook.com/thetutulist">
            <IconSocialMediaFacebook />
          </a>
          <a href="https://www.instagram.com/tutulist">
            <IconSocialMediaInstagram />
          </a>
          <a href="https://www.tiktok.com/discover/tutulist">
            <IconSocialMediaTiktok />
          </a>
        </div>
        {login}
        {signup}
        {drawerData}
      </div>
    );
  }

  // const notificationCountBadge =
  //   notificationCount > 0 ? (
  //     <NotificationBadge className={css.notificationBadge} count={notificationCount} />
  //   ) : null;

  const displayName = user.attributes.profile.firstName;
  // const currentPageClass = page => {
  //   const isAccountSettingsPage =
  //     page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
  //   return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  // };

  return (
    <div className={css.root}>
      <div className={css.userAccount}>
        {/* <AvatarLarge className={css.avatar} user={currentUser} /> */}
        <div className={css.rightSec}>
          {/* <div className={css.socialMediaLinks}>
            <a href="https://www.facebook.com/thetutulist">
              <IconSocialMediaFacebook />
            </a>
            <a href="https://www.instagram.com/tutulist">
              <IconSocialMediaInstagram />
            </a>
            <a href="https://www.tiktok.com/discover/tutulist">
              <IconSocialMediaTiktok />
            </a>
          </div> */}
        </div>
      </div>
      <div className={css.content}>
        <div className={css.greetingSec}>
          <span className={css.greeting}>
            <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
          </span>
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <FormattedMessage id="TopbarMobileMenu.logoutLink" />
          </InlineTextButton>
        </div>
        <div className={css.links}>
          <InlineTextButton
            className={css.linkItem}
            onClick={() =>
              history.push(
                createResourceLocatorString('ProfileSettingsPage', routeConfiguration(), {}, {})
              )
            }
          >
            <FormattedMessage id="TopbarMobileMenu.yourProfile" />
          </InlineTextButton>
          {isAuthenticated ? (
            <InlineTextButton
              className={css.linkItem}
              onClick={() =>
                history.push(
                  createResourceLocatorString('DashboardPage', routeConfiguration(), {}, {})
                )
              }
            >
              <FormattedMessage id="TopbarMobileMenu.DashboardPage" />
            </InlineTextButton>
          ) : null}
          {isAuthenticated ? (
            <InlineTextButton
              className={css.linkItem}
              onClick={() =>
                history.push(
                  createResourceLocatorString('OffersPage', routeConfiguration(), {}, {})
                )
              }
            >
              <FormattedMessage id="OffersPage.title" />
            </InlineTextButton>
          ) : null}
          <InlineTextButton
            className={css.linkItem}
            onClick={() =>
              history.push(
                createResourceLocatorString('InboxPage', routeConfiguration(), { tab: 'sales' }, {})
              )
            }
          >
            <FormattedMessage id="TopbarMobileMenu.purchasesAndSales" />
          </InlineTextButton>
          <InlineTextButton
            className={css.linkItem}
            onClick={() =>
              history.push(
                createResourceLocatorString(
                  'MessagesOrderPage',
                  routeConfiguration(),
                  { tab: 'enquiry' },
                  {}
                )
              )
            }
          >
            <FormattedMessage id="TopbarMobileMenu.messagesLink" />
          </InlineTextButton>
          <a className={css.linkItem} href="/listings">
            <FormattedMessage id="TopbarMobileMenu.ManageListingsPageLink" />
          </a>
          <InlineTextButton
            className={css.linkItem}
            onClick={() =>
              history.push(
                createResourceLocatorString('ContactDetailsPage', routeConfiguration(), {}, {})
              )
            }
          >
            <FormattedMessage id="TopbarMobileMenu.ContactDetailsPageLink" />
          </InlineTextButton>
        </div>
        {drawerData}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
