import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
// import { parse } from '../../util/urlHelpers';
import {
  TRANSITIONS,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_ENQUIRE,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getImageVariants } from '../TransactionPage/TransactionPage.duck';
import { denormalisedResponseEntities } from '../../util/data';

const INBOX_PAGE_SIZE = 100;

const commonApiQueryParams = {
  include: [
    'listing',
    'listing.images',
    'provider',
    'provider.profileImage',
    'customer',
    'customer.profileImage',
    'booking',
    'messages',
    'messages.sender',
  ],
  'fields.transaction': [
    'lastTransition',
    'lastTransitionedAt',
    'transitions',
    'payinTotal',
    'payoutTotal',
    'lineItems',
    'protectedData',
  ],
  'fields.listing': ['title', 'publicData', 'description'],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
  'fields.image': ['variants.square-small', 'variants.square-small2x'],
};

const enquiryOrOfferTransitions = [
  TRANSITION_ENQUIRE,
  // TRANSITION_ACCEPT_OFFER,
  // TRANSITION_DECLINE_OFFER,
  // TRANSITION_COUNTER_OFFER,
  // TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
  // TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
];

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_CUSTOM_TX_SUCCESS = 'app/MessagesPage/FETCH_CUSTOM_TX_SUCCESS';
export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/MessagesPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/MessagesPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/MessagesPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/MessagesPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/MessagesPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/MessagesPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/MessagesPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/MessagesPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/MessagesPage/SEND_MESSAGE_ERROR';

export const UPDATE_OFFER_PROGRESS = 'app/MessagesPage/UPDATE_OFFER_PROGRESS';
export const UPDATE_OFFER_SUCCESS = 'app/MessagesPage/UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_ERROR = 'app/MessagesPage/UPDATE_OFFER_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  sendMessageInProgress: false,
  sendMessageError: null,
  sendMessageError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  updateOfferInProgress: false,
  updateOfferSuccess: false,
  updateOfferError: null,
};

// const mergeEntityArrays = (a, b) => {
//     return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
// };
let allMessages = [];
export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_OFFER_PROGRESS:
      return { ...state, updateOfferInProgress: true };
    case UPDATE_OFFER_SUCCESS:
      return { ...state, updateOfferSuccess: true, updateOfferInProgress: false };
    case UPDATE_OFFER_ERROR:
      return { ...state, updateOfferError: true, updateOfferInProgress: false };
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: payload.messages,
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_CUSTOM_TX_SUCCESS:
      allMessages.push(payload.data.data);
      const mergedMessages = [].concat.apply([], allMessages);
      const ids = mergedMessages && mergedMessages.map(l => l?.id);

      let uniqueArr =
        ids &&
        ids.length &&
        ids.reduce((unique, o) => {
          if (!unique.some(obj => obj['_sdkType'] === o['_sdkType'] && obj?.uuid === o?.uuid)) {
            unique.push(o);
          }
          return unique;
        }, []);
      return {
        ...state,
        customTransactions: uniqueArr,
        fetchInProgress: false,
      };
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const selectUpdateOfferInProgress = state => state.MessagesPage.updateOfferInProgress;
export const selectUpdateOfferSuccess = state => state.MessagesPage.updateOfferSuccess;
export const selectUpdateOfferError = state => state.MessagesPage.updateOfferError;

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });

const fetchCustomTxSuccess = response => ({
  type: FETCH_CUSTOM_TX_SUCCESS,
  payload: { data: response.data },
});

const updateOfferProgress = () => ({
  type: UPDATE_OFFER_PROGRESS,
});

const updateOfferSuccess = () => ({
  type: UPDATE_OFFER_SUCCESS,
});
const updateOfferError = () => ({
  type: UPDATE_OFFER_ERROR,
});

const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});

const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.MessagesPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: INBOX_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...getImageVariants(),
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      dispatch(fetchMessagesSuccess(messages, pagination));
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1));
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

const queryTransactions = async (
  dispatch,
  sdk,
  queryParams,
  fetchAction,
  successAction,
  errorAction
) => {
  try {
    const response = await sdk.transactions.query(queryParams);
    dispatch(fetchAction(response));
    dispatch(addMarketplaceEntities(response));
    dispatch(successAction(response));

    const meta = response?.data?.meta;
    const totalPages = meta?.totalPages;
    const totalItems = meta?.totalItems;
    let page = meta?.page;

    while (totalItems > 0 && totalPages !== page) {
      page += 1;
      const res = await sdk.transactions.query({ ...queryParams, page });
      dispatch(addMarketplaceEntities(res));
      dispatch(fetchAction(res));
    }

    return response;
  } catch (e) {
    dispatch(errorAction(storableError(e)));
    throw e;
  }
};

export const sendMessage = (txId, message, tab) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(async response => {
      const messageId = response.data.data.id;

      const transitions = tab === 'order' ? TRANSITIONS : enquiryOrOfferTransitions;

      const apiQueryParams = {
        lastTransitions: transitions,
        ...commonApiQueryParams,
        page: 1,
        per_page: INBOX_PAGE_SIZE,
      };

      await queryTransactions(
        dispatch,
        sdk,
        apiQueryParams,
        fetchCustomTxSuccess,
        fetchOrdersOrSalesSuccess,
        fetchOrdersOrSalesError
      );
      dispatch(sendMessageSuccess());
      return messageId;
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  const { tab } = params;
  const transitions = tab === 'order' ? TRANSITIONS : enquiryOrOfferTransitions;
  dispatch(fetchOrdersOrSalesRequest());

  const apiQueryParams = {
    lastTransitions: transitions,
    ...commonApiQueryParams,
    per_page: INBOX_PAGE_SIZE,
  };

  return queryTransactions(
    dispatch,
    sdk,
    apiQueryParams,
    fetchCustomTxSuccess,
    fetchOrdersOrSalesSuccess,
    fetchOrdersOrSalesError
  );
};

export const updateOffer = ({ id, transition, message }) => async (dispatch, getState, sdk) => {
  const apiQueryParams = {
    lastTransitions: enquiryOrOfferTransitions,
    ...commonApiQueryParams,
    per_page: INBOX_PAGE_SIZE,
  };

  dispatch(updateOfferProgress());
  try {
    await sdk.transactions.transition(
      {
        id,
        transition,
        params: {},
      },
      { expand: true }
    );

    await sdk.messages.send({ transactionId: id, content: message });

    await queryTransactions(
      dispatch,
      sdk,
      apiQueryParams,
      fetchCustomTxSuccess,
      fetchOrdersOrSalesSuccess,
      fetchOrdersOrSalesError
    );
    await dispatch(updateOfferSuccess());
    return id;
  } catch (e) {
    dispatch(updateOfferError());
  }
};
