import React from 'react';
import { HomeIconMobile, IconHeart, IconSearch, NamedLink } from '../../components';
import IconBag from '../../components/IconBag/IconBag';
import IconMessage from '../../components/IconMessage/IconMessage';
import { useMyContext } from '../../contexts/StateHolder';
import { isArrayLength } from '../../util/dataExtractors';

import css from './GlobalBottomNavMobile.module.css';
import { userBookmarkSelector } from '../../ducks/user.duck';
import { selectIsAuthenticated } from '../../ducks/Auth.duck';
import { useSelector } from 'react-redux';

function GlobalBottomNavMobile() {
  const { cartItems: localBookmarks } = useMyContext();

  const authenticatedUserBookmarks = useSelector(userBookmarkSelector);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const bookmarks = isAuthenticated ? authenticatedUserBookmarks : localBookmarks;

  return (
    <div className={css.bottomNavbar}>
      <NamedLink name="SearchPage">
        <IconSearch />
      </NamedLink>
      <NamedLink rel="nofollow" name="FavouritesPage" className={css.navLink}>
        <IconHeart />
      </NamedLink>
      <NamedLink name="LandingPage">
        <HomeIconMobile />
      </NamedLink>
      <NamedLink rel="nofollow" name="CartPage" className={css.navLink}>
        <IconBag />
        {isArrayLength(bookmarks) ? (
          <span className={css.badge}>{bookmarks?.length || 0}</span>
        ) : null}
      </NamedLink>
      <NamedLink rel="nofollow" name="InboxPage" params={{ tab: 'all' }}>
        <IconMessage />
      </NamedLink>
    </div>
  );
}

export default GlobalBottomNavMobile;
