import React from 'react';
import wallet from './wallet.png';

function IconWallet() {
  return (
    <>
      <svg
        fill="#000000"
        viewBox="0 0 24 24"
        id="wallet-money-2"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            id="secondary"
            d="M10.91,4.06,6.74,10H5.58a1,1,0,0,1-.88-.54,1,1,0,0,1,.06-1l3.51-5A1,1,0,0,1,8.91,3a1,1,0,0,1,.75.16Zm8.66,1.56L14.66,2.18a1,1,0,0,0-1.39.25l-1.55,2.2L8,10h9.25A1,1,0,0,0,18,9.57L19.82,7a1,1,0,0,0-.25-1.39Z"
            fill="#c6488c"
          ></path>
          <rect id="primary" x="2" y="8" width="19" height="14" rx="2" fill="#ddd"></rect>
          <rect id="secondary-2" x="16" y="13" width="6" height="4" rx="1" fill="#c6488c"></rect>
        </g>
      </svg>
      {/* <img src={wallet} alt="E Wallet" style={{ width: '28px' }} /> */}
    </>
  );
}

export default IconWallet;
