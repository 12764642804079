import config from '../config';

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

export const pushEventToDataLayer = (params, event, totalOrderValue = null) => {
  const eventParams = {
    currencyCode: config.currency,
    add: {
      products: Array.isArray(params) ? params : [params],
    },
  };
  if (totalOrderValue && totalOrderValue > 0) {
    Object.assign(eventParams, { value: totalOrderValue });
  }

  window.dataLayer.push({
    event,
    ecommerce: eventParams,
  });
};
