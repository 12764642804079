import React, { useState } from 'react';
import classNames from 'classnames';
import { IconLeftArrow, IconRightArrow } from '../../components';
import Carousel from 'react-multi-carousel';
import { isArrayLength } from '../../util/dataExtractors';
import {
  costumesQuickFilters,
  filterSliderResponsive,
  quickFilters,
} from '../../config/marketplace-custom-config';
import { createResourceLocatorString } from '../../util/routes';
import {
  convertQueryParamsArrayToObject,
  convertQuickFiltersObjectToArray,
  expandQueryParams,
  useStateRef,
} from '../../util/genericHelpers';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../util/urlHelpers';
import routeConfiguration from '../../routing/routeConfiguration';
import { BASE_SEARCH_PAGE, COSTUME_SEARCH_PAGE } from '../../util/enums';

import css from './SearchQuickFilters.module.css';
import './ExtraConfigs/React-Multi-Carousel.css';

const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="carousel-button-group">
      <div className={classNames(css.sliderControls, css.leftArrow)} onClick={previous}>
        <IconLeftArrow />
      </div>
      <div className={classNames(css.sliderControls, css.rightArrow)} onClick={next}>
        <IconRightArrow />
      </div>
    </div>
  );
};

function SearchQuickFilters(props) {
  const { isCostumes = false } = props;
  const filters = isCostumes ? costumesQuickFilters : quickFilters;
  const searchBasePage = isCostumes ? COSTUME_SEARCH_PAGE : BASE_SEARCH_PAGE;

  const [selectedFilters, setQuickFilters, quickFilterRef] = useStateRef([]);

  const history = useHistory();
  const location = useLocation();
  const urlQueryParams = parse(location.search);

  const paramsStringsToObject =
    Object.keys(urlQueryParams)?.length &&
    expandQueryParams(convertQuickFiltersObjectToArray(urlQueryParams));

  const onFilter = item => {
    const index = isArrayLength(paramsStringsToObject)
      ? paramsStringsToObject.findIndex(p => p === item)
      : -1;
    if (index > -1) {
      paramsStringsToObject.splice(index, 1);
      const search = convertQueryParamsArrayToObject(paramsStringsToObject) || {};
      history.push(
        createResourceLocatorString(searchBasePage, routeConfiguration(), {}, { ...search })
      );
    } else {
      selectedFilters.push(item);
      const search = convertQueryParamsArrayToObject([...new Set(quickFilterRef.current)]) || {};
      const extraParams = urlQueryParams ? { ...urlQueryParams, ...search } : { ...search };
      history.push(
        createResourceLocatorString(searchBasePage, routeConfiguration(), {}, extraParams)
      );
    }
  };

  const filterContent =
    isArrayLength(filters) &&
    filters.map((item, i) => {
      return (
        <div
          key={i}
          onClick={() => onFilter(item?.search, i)}
          className={classNames(css.link, {
            [css.active]: paramsStringsToObject
              ? paramsStringsToObject?.includes(item?.search)
              : false,
          })}
        >
          <div className={css.quickFilter}>
            {item?.icon}
            <span>{item?.name}</span>
          </div>
        </div>
      );
    });

  if (!isCostumes) {
    return <div className={css.filters}></div>;
  }

  return (
    <div className={css.filters}>
      {isMobile ? (
        <div className={css.mobileFilterCategories}> {filterContent}</div>
      ) : (
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          className={css.itemSlider}
          containerClass={css.sliderContainer}
          dotListClass={css.sliderDots}
          draggable
          focusOnSelect={false}
          customButtonGroup={<ButtonGroup />}
          infinite={true}
          itemClass={css.sliderItem}
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={filterSliderResponsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {filterContent}
        </Carousel>
      )}
    </div>
  );
}

export default SearchQuickFilters;
