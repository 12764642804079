import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { Flip, toast } from 'react-toastify';
import { isArrayLength } from './dataExtractors';
import { stringifyDateToISO8601 } from './dates';

export const copyToClipboard = async text => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus({
      preventScroll: true,
    });
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  } catch (e) {
    console.error(`Copying failed with error: ${e}`);
    throw e;
  }
};

export const isMobileLayout = () => {
  const isMobile = typeof window !== 'undefined' && window?.innerWidth < 768;
  return !!isMobile;
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const onTriggerError = () => {
  toast.error(
    `Sorry, we encountered an error. Please try again later. If the issue persists, kindly contact our support team for further assistance.`,
    {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Flip,
    }
  );
};

export const decryptNumber = (encrypted, key) => {
  const decrypted = encrypted ^ key;
  return decrypted;
};

export const groupByHelper = (xs, f) => {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
};

export const convertQuickFiltersObjectToArray = obj => {
  if (Object.keys(obj).length === 0) {
    return []; // Return an empty array if the object has no keys
  }

  return Object.keys(obj).map(key => `${key}=${obj[key]}`);
};

export const expandQueryParams = inputArray => {
  const outputArray = [];
  inputArray.forEach(item => {
    // Split the item into key and value(s)
    const [key, value] = item.split('=');
    // Check if the value contains a comma, indicating multiple values
    if (value.includes(',')) {
      // Split the value into an array of values
      const values = value.split(',');
      // Push each value into the output array with its corresponding key
      values.forEach(val => outputArray.push(`${key}=${val}`));
    } else {
      // If there's only one value, push the item as is
      outputArray.push(item);
    }
  });
  return outputArray;
};

export const convertQueryParamsArrayToObject = array => {
  const obj = {};
  array.forEach(item => {
    const [key, rawValue] = item.split('=');
    const value = rawValue === 'true' ? true : rawValue === 'false' ? false : rawValue;

    // If the object already has the key, push to the existing array
    if (obj.hasOwnProperty(key)) {
      // If it's not already an array, make it an array
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      // Otherwise, just assign the value
      obj[key] = value;
    }
  });

  return obj;
};

// Helper function to determine if a title contains all the keywords
function containsExactKeywords(title, keywords) {
  return keywords.every(keyword => new RegExp(`\\b${keyword}\\b`, 'i').test(title));
}

// Custom sort function
export const customSortListingsByKeyword = (listings, validQueryParams) => {
  const queryKeywords = validQueryParams?.keywords?.toLowerCase().split(' ') || [];

  // First, filter out listings that don't contain the exact keywords
  const filteredListings = listings?.filter(listing => {
    return containsExactKeywords(listing?.attributes?.title, queryKeywords);
  });

  // Then, sort the filtered listings
  return filteredListings.sort((a, b) => {
    const titleA = a?.attributes?.title?.toLowerCase();
    const titleB = b?.attributes?.title?.toLowerCase();

    // Check if title contains all keywords
    const allKeywordsMatchA = containsExactKeywords(titleA, queryKeywords);
    const allKeywordsMatchB = containsExactKeywords(titleB, queryKeywords);

    // Priority to listings containing all keywords
    if (allKeywordsMatchA && !allKeywordsMatchB) return -1;
    if (!allKeywordsMatchA && allKeywordsMatchB) return 1;

    // Fallback to alphabetical order if both contain all keywords
    return titleA.localeCompare(titleB);
  });
};

export const showLimitedText = (text, maxLength) => {
  if (typeof text !== 'string' || typeof maxLength !== 'number' || maxLength <= 0) {
    return 'Invalid input';
  }

  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + '...';
  }
};

export const customSortByAlphabeticals = arr => {
  // Separate numbers and alphabeticals
  const numbers = arr.filter(obj => !isNaN(obj.key));
  const alphabeticals = arr.filter(obj => isNaN(obj.key));

  // Sort alphabeticals first
  alphabeticals.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  // Concatenate both arrays
  const sortedArray = alphabeticals.concat(numbers);

  return sortedArray;
};

export const renameObjectKeys = (obj, newKeys) => {
  const renamedObj = {};
  for (const key in obj) {
    if (newKeys[key]) {
      renamedObj[newKeys[key]] = obj[key];
    } else {
      renamedObj[key] = obj[key];
    }
  }
  return renamedObj;
};

const getPriceByDuration = (pricingOptions, duration) =>
  Number(pricingOptions?.find(p => p?.duration === duration)?.price || 0);

export const calculateBreakdownLabel = (units, pricingOption, bookingDates) => {
  const pricePerDay = getPriceByDuration(pricingOption, 'day');
  const pricePerWeek = getPriceByDuration(pricingOption, 'week');
  const pricePerMonth = getPriceByDuration(pricingOption, 'month');

  let breakdown = [];
  let remainingDays = units;
  const daysInStartMonth = moment(bookingDates?.startDate).daysInMonth(); // Days in start month

  // If units are 1 or less, use per day rate
  if (units <= 1 && pricePerDay > 0) {
    breakdown.push(`$${pricePerDay * units} (${units}d)`);
  } else {
    // Calculate months
    if (remainingDays >= daysInStartMonth && pricePerMonth > 0) {
      const months = Math.floor(remainingDays / daysInStartMonth);
      if (months > 0) {
        breakdown.push(`$${pricePerMonth * months} (${months}m)`);
        remainingDays -= months * daysInStartMonth;
      }
    }

    // Calculate weeks
    if (remainingDays >= 7 && pricePerWeek > 0) {
      const weeks = Math.floor(remainingDays / 7);
      if (weeks > 0) {
        breakdown.push(`$${pricePerWeek * weeks} (${weeks}w)`);
        remainingDays -= weeks * 7;
      }
    }

    // Calculate remaining days
    if (remainingDays > 0 && pricePerDay > 0) {
      breakdown.push(`$${pricePerDay * remainingDays} (${remainingDays}d)`);
    }
  }

  return `Subtotal: ${breakdown.join(' + ')}`;
};

/**
 * Function to map over an array of objects and change the key 'values' to 'value'
 * @param {Array<Object>} array - The array of objects to transform
 * @return {Array<Object>} A new array with the transformed objects
 */
export const transformArrayKeys = array => {
  return isArrayLength(array)
    ? array.map(item => {
        const newItem = { ...item }; // Create a shallow copy to avoid mutating the original object
        if ('values' in newItem) {
          newItem.key = newItem.values; // Assign the value from 'values' to 'key'
          delete newItem.values; // Remove the old key
        }
        return newItem;
      })
    : [];
};

export const getChildAndAdultsOptions = options => {
  const adultOptions =
    options.filter(
      option =>
        (option.key && option.key.toLowerCase().includes('adult')) || // Check 'key' if it exists
        (option.label && option.label.toLowerCase().includes('adult')) // Check 'label' if it exists
    ) || [];
  const childOptions =
    options.filter(
      option =>
        (option.key && option.key.toLowerCase().includes('child')) || // Check 'key' if it exists
        (option.label && option.label.toLowerCase().includes('child')) // Check 'label' if it exists
    ) || [];

  return { adultOptions, childOptions };
};

/**
 * This function changes the keys of the booking dates from `startDate` and `endDate` to `start` and `end`.
 * It takes an object that may contain the properties `startDate` and `endDate`.
 * @param {Object} bookingDates - The object containing the booking dates with old key names.
 * @returns {Object} - A new object with the key names changed, or null if input is null or undefined.
 */
export const changeDateKeys = bookingDates => {
  if (!bookingDates) return null; // Return null if the input is null or undefined

  const { startDate, endDate } = bookingDates;

  // Create a new object with the keys renamed
  return {
    start: startDate,
    end: endDate,
  };
};

export const formatDatesValue = dates => {
  const { startDate, endDate } = dates || {};
  const start = startDate && stringifyDateToISO8601(startDate);
  const end = endDate && stringifyDateToISO8601(endDate);
  return start && end ? `${start},${end}` : null;
};

export const parseDateRange = dateRange => {
  if (!dateRange) return null;

  const dates = dateRange.split(',');
  const startDate = dates[0] ? new Date(dates[0]) : null;
  const endDate = dates[1] ? new Date(dates[1]) : null;

  return { startDate, endDate };
};
