import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
} from '../../util/types';

import css from './OrderBreakdown.module.css';
import { getPricingDetails, isArrayLength } from '../../util/dataExtractors';
import { calculateBreakdownLabel, changeDateKeys } from '../../util/genericHelpers';
import moment from 'moment';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, non-reversal line items
 */
const nonCommissionNonReversalLineItems = lineItems => {
  return lineItems.filter(item => !isCommission(item) && !item.reversal);
};

/**
 * Check if there is a commission line-item for the given user role.
 */
const hasCommission = (lineItems, userRole) => {
  let commissionLineItem = null;

  if (userRole === 'customer') {
    commissionLineItem = lineItems.find(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION);
  } else if (userRole === 'provider') {
    commissionLineItem = lineItems.find(item => item.code === LINE_ITEM_PROVIDER_COMMISSION);
  }
  return !!commissionLineItem;
};

const LineItemCustomTotal = props => {
  const {
    lineItems,
    unitType,
    userRole,
    intl,
    isRentals,
    bookingDates: specBookingDates,
    transaction,
    listing,
  } = props;

  const datesMaybe = specBookingDates?.bookingDates
    ? changeDateKeys(specBookingDates?.bookingDates)
    : null;

  const bookingDates = datesMaybe || transaction?.booking?.attributes;

  // const refund = lineItems.find(item => item.code === unitType && item.reversal);

  // All Rentals line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(lineItems);
  const rentalLineItems =
    isArrayLength(subTotalLineItems) &&
    subTotalLineItems.filter(l => l?.code?.includes(LINE_ITEM_DAY));
  const subTotal = lineItemsTotal(rentalLineItems);
  const formattedSubTotal = subTotalLineItems.length > 0 ? formatMoney(intl, subTotal) : null;

  const cartItems =
    isArrayLength(lineItems) &&
    lineItems.filter(l => !l.reversal && l?.code?.includes(LINE_ITEM_UNITS));

  const sum =
    isArrayLength(cartItems) &&
    cartItems.reduce((partialSum, item) => {
      const quantity = Math.abs(item.quantity);
      const price = item.unitPrice ? item.unitPrice.amount : 0; // Assuming item.unitPrice.amount is a number
      return partialSum + price * quantity;
    }, 0);

  const customTotal = sum && new Money(sum, 'USD') && formatMoney(intl, new Money(sum, 'USD'));

  if (isRentals) {
    const units = moment(bookingDates?.end).diff(bookingDates?.start, 'days');
    const subLabel = calculateBreakdownLabel(units, getPricingDetails(listing), bookingDates);

    return (
      <>
        <hr className={css.totalDivider} />
        <div className={css.subTotalLineItem}>
          <span className={css.itemLabel}>{subLabel}</span>
          <span className={css.itemValue}>{formattedSubTotal}</span>
        </div>
      </>
    );
  }

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.subTotal" />
        </span>
        <span className={css.itemValue}>{customTotal}</span>
      </div>
    </>
  );
};

LineItemCustomTotal.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  userRole: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomTotal;
