import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';
import InfoPopup from '../InfoPopup/InfoPopup';

const { Money } = sdkTypes;

const LineItemShippoShippingFeeMaybe = props => {
  const {
    lineItems,
    intl,
    shippingFlowMessage = false,
    isRentals = false,
    shippingInfo = null,
    isThresholdReachedAndProvider,
    // isCheckoutPage,
    // isCustomer,
  } = props;

  const getShippingInfoMessage = () => {
    if (isRentals) {
      return 'This fee is for return shipping. By including this fee, we ensure that returning the rental item is hassle-free for you.';
    }
    if (isThresholdReachedAndProvider) {
      return 'You are being charged this fee because you have selected to cover the cost of shipping and offer free shipping to buyers.';
    }
    return null;
  };

  const shippingInfoMessage = getShippingInfoMessage();

  const shippingFeeLineItem = lineItems.filter(item => item.code === LINE_ITEM_SHIPPING_FEE);

  return shippingFeeLineItem ? (
    shippingFlowMessage() ? (
      <>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="OrderBreakdown.shippingFee" />
            {shippingInfoMessage && <InfoPopup info={shippingInfoMessage} />}
          </span>
          <span className={css.itemValue}>{shippingFlowMessage()}</span>
        </div>
      </>
    ) : null
  ) : null;
};

LineItemShippoShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippoShippingFeeMaybe;
