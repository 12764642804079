import React, { Component, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import config from '../../config';
import { AspectRatioWrapper, ResponsiveImage } from '../../components';

import css from './SoldCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const SoldCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    isProfile,
    isSearchPage = false,
  } = props;

  const currentListing = ensureListing(listing);
  const { title = '', publicData, soldStock = 1 } = currentListing?.attributes || {};

  let firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1 } = config.listing;

  const [isHovered, setHovered] = useState('');
  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  if (isHovered && currentListing && currentListing.images && currentListing.images.length > 1) {
    firstImage = currentListing.images[1];
  }
  const photos = publicData && publicData.photos;
  const firstPhoto = photos && photos.length && photos[0];

  return (
    <div className={css.soldCard}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
        onMouseOver={() => setHovered('hover')}
        onMouseOut={() => setHovered('')}
        style={{ height: aspectHeight }}
        isProfile={isProfile}
      >
        {firstPhoto ? (
          <div className={isProfile ? css.firstPhotoProfile : css.firstPhoto}>
            <img src={firstPhoto} alt="First Photo" />
          </div>
        ) : (
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={isSearchPage ? ['scaled-large'] : ['scaled-xlarge', 'listing-card-2x']}
            sizes={renderSizes}
          />
        )}
        <span className={css.soldStock}>{soldStock} Sold</span>
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>Size: {publicData && publicData.size}</div>
        </div>
      </div>
    </div>
  );
};

SoldCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

SoldCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(SoldCardComponent);
