import React, { Component, useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage, Button, IconSpinner, Icons } from '..';
import {
  capitalizeFirstLetter,
  getBookmarks,
  getStoreName,
  getUsername,
  isArrayLength,
} from '../../util/dataExtractors';
import { pushEventToDataLayer } from '../../util/gtm';
import { GOOGLE_TAGS } from '../../config/enums';
import { useMyContext } from '../../contexts/StateHolder';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookmarks, selectCurrentUser } from '../../ducks/user.duck';
import { selectIsAuthenticated } from '../../ducks/Auth.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { isWindowDefined } from '../../util/cartHelpers';
import {
  // clearBookmarks,
  // clearBookmarksLoading,
  searchListings,
} from '../../containers/CartPage/CartPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { removeStoreProductMailChimp } from '../../ducks/Global.duck';

import css from './CartCard.module.css';
import { useMyContextFunctions } from '../../contexts/ContextFunctions';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const { Money } = sdkTypes;

const filterGroupedCartItemsById = (nestedArray, targetUuid) => {
  return nestedArray.map(subArray =>
    subArray.filter(item => {
      // Check for the id object within the item and match against the uuid
      return !(item.id && item.id.uuid === targetUuid);
    })
  );
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const CartCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    currentCartItem,
    setStockDetails,
    stockDetails,
    offerPriceIfAny,
    removeFromGroupedListings,
    setGlobalCartItems,
    cartItems,
    setCartItems,
  } = props;

  const [currentItem, setCurrentItem] = useState();
  const { addToCart } = useMyContextFunctions();

  useEffect(() => {
    setCurrentItem(currentCartItem?.item);
  }, [currentCartItem?.item?.stockCount]);

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const dispatch = useDispatch();
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  let { title = '', price, publicData } = currentListing.attributes;

  if (offerPriceIfAny) {
    price = new Money(offerPriceIfAny.offerPrice * 100, 'USD');
  }

  const currentStock = currentListing && currentListing.currentStock;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);

  const storeName = getStoreName(author);

  let firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const [isHovered, setHovered] = useState('');

  if (isHovered && currentListing && currentListing.images && currentListing.images.length > 1) {
    firstImage = currentListing.images[1];
  }

  const itemTotal =
    price && currentItem?.stockCount
      ? (price.amount / 100) * currentItem?.stockCount
      : (price.amount / 100) * 1;

  const availableQuantity =
    currentStock && currentStock.attributes && currentStock.attributes.quantity;

  const photos = publicData && publicData.photos;
  const firstPhoto = photos && photos.length && photos[0];

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const productPrice = price && price.amount / 100;
  const username = getUsername(author);
  const authorDisplayName = author && userDisplayNameAsString(author, '');

  let localBookmarks =
    isWindowDefined() &&
    window.localStorage.getItem('localBookmarks') &&
    window.localStorage.getItem('localBookmarks').length > 0
      ? window.localStorage.getItem('localBookmarks')
      : [];

  if (typeof localBookmarks === 'string') {
    localBookmarks = isWindowDefined() && JSON.parse(window.localStorage.getItem('localBookmarks'));
  }

  const bookmarks = isAuthenticated ? getBookmarks(currentUser) : localBookmarks;

  // const tagParams = {
  //   item_id: id,
  //   item_name: title,
  //   price: productPrice,
  //   quantity: 1,
  //   index: (bookmarks?.length || 0) + 1,
  //   item_brand: publicData?.brand,
  //   item_category: publicData?.category,
  //   item_category2: publicData?.condition,
  //   item_category3: publicData?.color,
  //   item_category4: publicData?.size,
  //   item_variant: publicData?.sku || '',
  // };

  const onUpdateCurrentUser = (array, isLikes = false, index) => {
    setRemoveInProgress(true);
    // dispatch(clearBookmarks());
    // pushEventToDataLayer(
    //   tagParams,
    //   index > -1 ? GOOGLE_TAGS.REMOVE_FROM_CART : GOOGLE_TAGS.ADD_TO_CART
    // );

    const bookmarks = Array.from(new Set(array));

    const params = isLikes ? { wishlist: Array.from(new Set(array)) } : { bookmarks };

    setCartItems(filterGroupedCartItemsById(cartItems, id));
    addToCart(bookmarks);

    onUpdateProfile({ publicData: { ...params } })
      .then(() => {
        dispatch(fetchBookmarks());
        // dispatch(clearBookmarksLoading());
        removeFromGroupedListings();
      })
      .then(() => setRemoveInProgress(false));
  };

  const onToggleQuanity = type => {
    const currentItemParams = {
      ...currentItem,
      stockCount: type === 'add' ? currentItem?.stockCount + 1 : currentItem?.stockCount - 1,
    };

    stockDetails[currentCartItem.index] = currentItemParams;
    setCurrentItem(currentItemParams);
    setStockDetails(stockDetails);
  };

  const isSyncedWithMC = publicData?.isSyncedWithMC;

  if (isArrayLength(bookmarks) && bookmarks.findIndex(e => e == id) === -1) {
    return <></>;
  }

  return (
    <div className={classes}>
      <div className={css.productDetails}>
        <div className={css.productDetailsLeft}>
          <div className={css.productImg}>
            <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
              <AspectRatioWrapper
                className={css.aspectRatioWrapper}
                width={aspectWidth}
                height={aspectHeight}
                onMouseOver={() => setHovered('hover')}
                onMouseOut={() => setHovered('')}
              >
                {firstPhoto ? (
                  <div className={css.firstPhoto}>
                    <img src={firstPhoto} alt="First Photo" />
                  </div>
                ) : (
                  <LazyImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={variants}
                    sizes={renderSizes}
                  />
                )}
              </AspectRatioWrapper>
            </NamedLink>
          </div>
          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                  {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </NamedLink>
              </div>
              <div className={css.authorInfo}>
                {publicData &&
                  publicData.brand &&
                  capitalizeFirstLetter(publicData && publicData.brand)}
              </div>
              <div className={css.authorInfo}>Size: {publicData && publicData.size}</div>
            </div>
            <div className={css.favorite}>
              {removeInProgress ? (
                <IconSpinner />
              ) : bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
                <span
                  className={css.bookmark}
                  onClick={e => {
                    if (isAuthenticated) {
                      e.preventDefault();
                      e.stopPropagation();
                      if (id) {
                        const index = bookmarks && bookmarks.findIndex(b => b == id);
                        if (index > -1) {
                          bookmarks && bookmarks.splice(index, 1);
                          setGlobalCartItems(bookmarks);
                          onUpdateCurrentUser(bookmarks, false, index);
                          dispatch(
                            removeStoreProductMailChimp({
                              productId: id,
                              currentUser,
                              isSyncedWithMC,
                            })
                          );
                        } else {
                          bookmarks.push(id);
                          setGlobalCartItems(bookmarks);
                          onUpdateCurrentUser(Array.from(new Set(bookmarks)), false, index);
                        }
                      }
                    } else {
                      const localIndex = localBookmarks && localBookmarks.findIndex(b => b == id);
                      if (localIndex > -1) {
                        localBookmarks && localBookmarks.splice(localIndex, 1);
                        const removedBookmarks = Array.from(new Set(localBookmarks));
                        isWindowDefined() &&
                          window.localStorage.setItem(
                            'localBookmarks',
                            JSON.stringify(removedBookmarks)
                          );
                        setGlobalCartItems(removedBookmarks);
                      } else {
                        localBookmarks.push(id);
                        const addedBookmarks = Array.from(new Set(localBookmarks));
                        isWindowDefined() &&
                          window.localStorage.setItem(
                            'localBookmarks',
                            JSON.stringify(addedBookmarks)
                          );
                        setGlobalCartItems(addedBookmarks);
                      }
                    }
                  }}
                >
                  Remove
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className={css.productDetailsRight}>
          <div className={css.stock}>
            <Button
              type="button"
              onClick={() => onToggleQuanity('subtract')}
              disabled={currentItem?.stockCount === 1}
            >
              <Icons name={currentItem?.stockCount > 1 ? 'subtractIcon' : 'disabledSubtractIcon'} />
            </Button>
            <span className={css.stockCount}>{currentItem?.stockCount || 1}</span>
            <Button
              type="button"
              onClick={() => onToggleQuanity('add')}
              disabled={availableQuantity == currentItem?.stockCount}
            >
              <Icons
                name={availableQuantity == currentItem?.stockCount ? 'disabledAddIcon' : 'addIcon'}
              />
            </Button>
          </div>
        </div>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            ${itemTotal}
          </div>
          {config.listing.showUnitTypeTranslations ? (
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CartCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

CartCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(CartCardComponent);
