import React from 'react';

function IconUser() {
  return (
    <svg
      width="22px"
      height="22px"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 490.002 490.002"
    >
      <g id="bold_copy_9_">
        <path
          d="M117.08,133.036c0,73.469,59.566,133.035,133.035,133.035c73.469,0,133.035-59.566,133.035-133.035
		c0-73.469-59.581-133.035-133.05-133.035C176.631,0.001,117.08,59.567,117.08,133.036z M352.51,133.036
		c0,56.473-45.938,102.41-102.41,102.41c-56.472,0-102.41-45.937-102.41-102.41c0-56.472,45.953-102.41,102.41-102.41
		C306.557,30.626,352.51,76.564,352.51,133.036z"
        />
        <path
          d="M245.001,490.001h245c0,0,1.7-137.812-134.413-178.651l-110.587,83.377l-110.587-83.361
		C-1.699,352.189,0.001,490.001,0.001,490.001H245.001L245.001,490.001z M128.963,345.604l97.602,73.577l18.436,13.888
		l18.436-13.904l97.602-73.577c65.292,25.618,87.251,79.625,94.616,113.772H245.001H34.454
		C41.942,425.244,63.992,371.161,128.963,345.604z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export default IconUser;
