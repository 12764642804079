import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_DISCOUNT, propTypes } from '../../util/types';
import { isArrayLength } from '../../util/dataExtractors';

import css from './OrderBreakdown.module.css';

const calculatePercentage = (lineUnitPrice, lineTotalPrice) => {
  const difference = lineTotalPrice - lineUnitPrice;
  const percentage = (difference / Math.abs(lineUnitPrice)) * 100;
  return Math.abs(percentage);
};

const LineItemDiscount = props => {
  const { lineItems } = props;

  const discountLineItem =
    isArrayLength(lineItems) && lineItems?.find(item => item?.code === LINE_ITEM_DISCOUNT);
  if (!discountLineItem) {
    return <></>;
  }

  const lineUnitPrice = discountLineItem.unitPrice.amount;
  const lineTotalPrice = discountLineItem.lineTotal.amount;
  const percentage = calculatePercentage(lineUnitPrice, Math.abs(lineTotalPrice));

  return (
    <>
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.discount" /> ({`${100 - percentage}% off`})
        </span>
        <span className={css.itemValue}>{`-$${Math.abs(lineTotalPrice / 100).toFixed(2)}`}</span>
      </div>
    </>
  );
};

LineItemDiscount.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscount;
