import React from 'react';

function IconNew() {
  return (
    <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490" width="24px" height="24px">
      <g>
        <path
          fill="#303030"
          d="M418.245,173.24V71.755H316.756L245,0l-71.755,71.755H71.755V173.24L0,245l71.755,71.756V418.24h101.49L245,490   l71.755-71.76h101.489V316.756L490,245L418.245,173.24z M397.755,308.271v89.479h-89.484L245,461.026l-63.271-63.276H92.245   v-89.479L28.974,245l63.271-63.275V92.245h89.484L245,28.974l63.27,63.271h89.484v89.479L461.026,245L397.755,308.271z"
        />
        <polygon
          fill="#303030"
          points="164.032,251.118 122.666,196.851 105.06,196.851 105.06,281.147 123.874,281.147 123.874,228.327 164.032,281.147    182.845,281.147 182.845,196.851 164.032,196.851  "
        />
        <polygon
          fill="#303030"
          points="222.4,247.018 260.143,247.018 260.143,230.978 222.4,230.978 222.4,213.614 264.365,213.614 264.365,196.851    203.587,196.851 203.587,281.147 265.689,281.147 265.689,264.504 222.4,264.504  "
        />
        <polygon
          fill="#303030"
          points="358.193,245.33 343.358,196.851 323.697,196.851 308.746,245.33 292.105,196.851 271.724,196.851 301.148,281.147    315.136,281.147 333.466,222.538 351.92,281.147 365.906,281.147 395.336,196.851 374.955,196.851  "
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export default IconNew;
