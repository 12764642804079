import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMyContext } from '../contexts/StateHolder';
import { selectIsAuthenticated } from '../ducks/Auth.duck';
import { fetchCurrentUser, selectCurrentUser } from '../ducks/user.duck';
import { getBookmarks, isArrayLength } from '../util/dataExtractors';
import { getKeyFromLocalStorage } from '../util/cartHelpers';
import { getValidCartIds } from '../ducks/Global.duck';
import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

// First create the context to hold shared functions
const MyContextFunctions = React.createContext();

// Then create a ContextFunctions wrapper component
// to hold the shared functions that the components need.
const ContextFunctions = props => {
  // Bring stateholders from another context
  const { cartItems = [], setCartItems } = useMyContext();

  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const bookmarks = getBookmarks(currentUser);
  const localBookmarks = getKeyFromLocalStorage('localBookmarks');

  const addToCart = cart => {
    setCartItems(cart);
  };

  const onCheckValidCartIds = async () => {
    const totalItems = await dispatch(getValidCartIds(bookmarks));
    if (totalItems?.length !== bookmarks?.length) {
      dispatch(
        updateProfile({
          publicData: {
            bookmarks: totalItems,
          },
        })
      );
    }
    setCartItems(totalItems);
  };

  useEffect(() => {
    if (cartItems?.length !== localBookmarks?.length) {
      setCartItems(localBookmarks);
    }
  }, [localBookmarks]);

  useEffect(() => {
    if (isAuthenticated && isArrayLength(bookmarks)) {
      setCartItems(bookmarks);
      onCheckValidCartIds();
    }
  }, [bookmarks]);

  useEffect(() => {
    if (!isAuthenticated) {
      setCartItems([]);
    }
  }, [isAuthenticated]);

  return (
    <MyContextFunctions.Provider
      value={{
        addToCart,
      }}
    >
      {props.children}
    </MyContextFunctions.Provider>
  );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
