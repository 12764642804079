import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { isMobile } from 'react-device-detect';

import css from './CategorySlider.module.css';
import CategoryItems from './CategoryItems';

const CategorySlider = ({
  title,
  hideTitle,
  headingClass,
  categories,
  brands,
  name,
  isBrandingSection,
  isTopCategorySection,
  sliderHeading,
}) => {
  const sliderButtons = (
    <div className={css.header}>
      <div className={headingClass}>
        <h2>
          <FormattedMessage id={title} /> <span className={css.titleHighlight}>{name}</span>
        </h2>
      </div>
    </div>
  );

  const items = [...(categories || []), ...(brands || [])];

  return (
    <div
      className={classNames(
        css.moreServices,
        css.fullWidth,
        isTopCategorySection ? css.topCategorySection : null,
        isBrandingSection ? css.brandingSection : null
      )}
    >
      <div className={classNames(css.moreServicesTitle, sliderHeading)}>{sliderButtons}</div>
      <div className={css.cardSlider}>
        {items.map((item, index) => (
          <div
            className={classNames(css.cardSliderItem, brands && css.cardSliderItemMakes)}
            key={index}
          >
            <CategoryItems item={item} hideTitle={hideTitle} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySlider;
