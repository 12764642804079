// ================ Action types ================ //

import { shopSmallUsers } from '../../util/api';

export const SEARCH_SELLERS_REQUEST = 'app/SellerSearchPage/SEARCH_SELLERS_REQUEST';
export const SEARCH_SELLERS_SUCCESS = 'app/SellerSearchPage/SEARCH_SELLERS_SUCCESS';
export const SEARCH_SELLERS_ERROR = 'app/SellerSearchPage/SEARCH_SELLERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  sellersData: [],
  searchSellersError: null,
};

const sellerSearchPageReducers = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_SELLERS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
      };
    case SEARCH_SELLERS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        sellersData: payload?.data || [],
      };
    case SEARCH_SELLERS_ERROR:
      return { ...state, searchInProgress: false, searchSellersError: true };
    default:
      return state;
  }
};

export default sellerSearchPageReducers;

// ================ Action creators ================ //

export const searchSellersRequest = () => ({
  type: SEARCH_SELLERS_REQUEST,
});

export const searchSellersSuccess = response => ({
  type: SEARCH_SELLERS_SUCCESS,
  payload: { data: response },
});

export const searchSellersError = () => ({
  type: SEARCH_SELLERS_ERROR,
});

export const searchShopSmallUsers = () => async (dispatch, getState, sdk) => {
  dispatch(searchSellersRequest());
  try {
    const response = await shopSmallUsers({});
    dispatch(searchSellersSuccess(response?.sellersData));
  } catch (error) {
    dispatch(searchSellersError());
  }
};

export const loadData = (params, search) => {
  return searchShopSmallUsers({});
};
