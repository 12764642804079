import React from 'react';

const IconCloseSmall = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7358 12.2031C12.8808 12.345 12.8808 12.5783 12.7358 12.7202C12.5952 12.8578 12.3704 12.8578 12.2298 12.7202L7.69938 8.28725C7.31069 7.90692 6.68931 7.90692 6.30062 8.28725L1.77023 12.7202C1.62963 12.8578 1.40485 12.8578 1.26424 12.7202C1.11924 12.5783 1.11924 12.345 1.26424 12.2031L5.7523 7.81155C6.15313 7.41934 6.15313 6.77425 5.7523 6.38205L1.26424 1.9905C1.11924 1.84862 1.11924 1.61526 1.26424 1.47339C1.40485 1.3358 1.62963 1.3358 1.77023 1.47339L6.30062 5.90634C6.68931 6.28668 7.31069 6.28668 7.69938 5.90634L12.2298 1.47339C12.3704 1.3358 12.5952 1.3358 12.7358 1.47339C12.8808 1.61526 12.8808 1.84862 12.7358 1.9905L8.2477 6.38205C7.84687 6.77425 7.84687 7.41934 8.2477 7.81155L12.7358 12.2031Z"
        fill="#484848"
        stroke="#484848"
      />
    </svg>
  );
};

export default IconCloseSmall;
