import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { RENTAL_TRANSITIONS, TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isArrayLength } from '../../util/dataExtractors';
import moment from 'moment';

const transitionGroups = {
  Canceled: [
    'transition/cancel',
    'transition/auto-cancel',
    'transition/cancel-with-paypal',
    'transition/auto-cancel-with-paypal',
  ],
  Shipped: [
    'transition/mark-delivered',
    'transition/mark-received',
    'transition/mark-delivered-with-paypal',
    'transition/mark-received-with-paypal',
  ],
  Disputed: ['transition/dispute', 'transition/dispute-with-paypal'],
  Enquiry: [
    'transition/request-payment-after-enquiry',
    'transition/request-payment-after-enquiry-with-paypal',
    'transition/custom-request-payment-after-enquiry',
  ],
  'Pending Shipping': [
    'transition/request-payment',
    'transition/request-payment-with-paypal',
    'transition/confirm-payment',
    'transition/confirm-payment-with-paypal',
    'transition/refund-initiated',
  ],
  'Payment Failed': ['transition/expire-payment', 'transition/expire-payment-with-paypal'],
  'Mark as received': [
    'transition/mark-received-from-purchased',
    'transition/mark-received-from-purchased-with-paypal',
  ],
  'Waiting for review': [
    'transition/review-1-by-customer',
    'transition/custom-review-1-by-customer',
    'transition/review-1-by-provider',
    'transition/review-2-by-provider',
    'transition/expire-provider-review-period',
    'transition/review-2-by-customer',
    'transition/expire-customer-review-period',
  ],
};

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      const transitions = tx?.attributes?.transitions || [];
      const firstTransition = isArrayLength(transitions) ? transitions[0] : null;
      const createdAt = moment(firstTransition?.createdAt).unix();
      return tx.attributes ? createdAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const shouldShowAllOrders = tab === 'all';

  const onlyFilter = onlyFilterValues[tab];

  if (!shouldShowAllOrders) {
    if (!onlyFilter) {
      return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
    }
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1, endDate = null, startDate = null, sku = null, status = null } =
    parse(search) || {};

  // if (status) {
  //   console.log(transitionGroups[status], TRANSITIONS);
  // }

  let listingIdPromise = Promise.resolve(); // Initialize a resolved promise

  if (sku) {
    listingIdPromise = sdk.listings
      .query({
        pub_sku: sku,
      })
      .then(res => {
        const listing = isArrayLength(res?.data?.data) && res?.data?.data[0];
        const listingId = listing?.id?.uuid;
        return listingId;
      });
  }

  return listingIdPromise
    .then(listingId => {
      const apiQueryParams = {
        lastTransitions: [...TRANSITIONS, ...RENTAL_TRANSITIONS],
        include: [
          'listing',
          'listing.images',
          'provider',
          'provider.profileImage',
          'customer',
          'customer.profileImage',
          'booking',
        ],
        'fields.transaction': [
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'protectedData',
        ],
        'fields.listing': ['title', 'publicData', 'description'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page,
        per_page: INBOX_PAGE_SIZE,
      };

      if (startDate && endDate) {
        Object.assign(apiQueryParams, {
          createdAtStart: new Date(startDate).toISOString(),
          createdAtEnd: new Date(endDate).toISOString(),
        });
      }

      if (listingId) {
        Object.assign(apiQueryParams, {
          listingId: listingId,
        });
      }

      if (!shouldShowAllOrders) {
        Object.assign(apiQueryParams, {
          only: onlyFilter,
        });
      }

      return sdk.transactions
        .query(apiQueryParams)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(fetchOrdersOrSalesSuccess(response));
          return response;
        })
        .catch(e => {
          dispatch(fetchOrdersOrSalesError(storableError(e)));
          throw e;
        });
    })
    .catch(e => {
      // Continue with querying transactions without listingId
      const apiQueryParams = {
        only: onlyFilter,
        lastTransitions: [...TRANSITIONS, ...RENTAL_TRANSITIONS],
        include: [
          'listing',
          'listing.images',
          'provider',
          'provider.profileImage',
          'customer',
          'customer.profileImage',
          'booking',
        ],
        'fields.transaction': [
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'protectedData',
        ],
        'fields.listing': ['title', 'publicData', 'description'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page,
        per_page: INBOX_PAGE_SIZE,
      };

      if (startDate && endDate) {
        Object.assign(apiQueryParams, {
          createdAtStart: new Date(startDate).toISOString(),
          createdAtEnd: new Date(endDate).toISOString(),
        });
      }

      if (!shouldShowAllOrders) {
        Object.assign(apiQueryParams, {
          only: onlyFilter,
        });
      }

      return sdk.transactions
        .query(apiQueryParams)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(fetchOrdersOrSalesSuccess(response));
          return response;
        })
        .catch(e => {
          dispatch(fetchOrdersOrSalesError(storableError(e)));
          throw e;
        });
    });
};
