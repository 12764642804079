export const isWindowDefined = () => {
  return typeof window !== 'undefined';
};

export const getKeyFromLocalStorage = (key, type = 'string') => {
  const storedData =
    isWindowDefined() &&
    window.localStorage.getItem(key) &&
    typeof window.localStorage.getItem(key) === type &&
    JSON.parse(window.localStorage.getItem(key) || 'Tutulist');
  return storedData;
};

export const setLocalStorageItems = (data, key) => {
  const uniqueData = Array.from(new Set(data));
  isWindowDefined() && window.localStorage.setItem(key, JSON.stringify(uniqueData));
  return uniqueData;
};

export const removeKeysFromLocalStorage = key => {
  isWindowDefined() && window.localStorage.removeItem(key);
};
