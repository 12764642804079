import React from 'react';

function IconRightArrow() {
  return (
    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.39418 10.95L0.479051 19.2407C0.316528 19.3912 0.189122 19.5787 0.106621 19.7888C0.0241196 19.9988 -0.0112811 20.2259 0.00313836 20.4525C0.0175578 20.6791 0.0814128 20.8993 0.1898 21.096C0.298186 21.2928 0.448219 21.4609 0.628372 21.5875C0.928268 21.815 1.29536 21.924 1.66468 21.8954C2.034 21.8668 2.38176 21.7024 2.64641 21.4312L11.5398 12.0452C11.6848 11.906 11.8006 11.7368 11.8799 11.5482C11.9591 11.3597 12 11.156 12 10.95C12 10.744 11.9591 10.5403 11.8799 10.3517C11.8006 10.1632 11.6848 9.99394 11.5398 9.85474L2.64641 0.46873C2.38176 0.197573 2.034 0.0331438 1.66468 0.00453917C1.29536 -0.0240655 0.928268 0.0849998 0.628372 0.312431C0.448219 0.439024 0.298186 0.607157 0.1898 0.803916C0.0814128 1.00067 0.0175578 1.22082 0.00313836 1.44744C-0.0112811 1.67407 0.0241196 1.90113 0.106621 2.1112C0.189122 2.32126 0.316528 2.50874 0.479051 2.65922L8.39418 10.95Z"
        fill="white"
      />
    </svg>
  );
}

export default IconRightArrow;
