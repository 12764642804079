import React from 'react';
import { useDropzone } from 'react-dropzone';
import IconAttachment from '../IconAttachment/IconAttachment';
import { isArrayLength } from '../../util/dataExtractors';
import { Flip, toast } from 'react-toastify';

const maxSize = 20 * 1024 * 1024; // 20MB in bytes

function DropZone(props) {
  const { onAttachmentUpload } = props;

  const onTriggerError = () => {
    toast.error(`The maximum file size is 20 MB. Please try again.`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Flip,
    });
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    // Handling accepted files
    if (isArrayLength(acceptedFiles))
      acceptedFiles.forEach(file => {
        onAttachmentUpload(file);
      });

    // Handling rejected files (e.g., files that exceed the size limit)
    if (isArrayLength(rejectedFiles)) {
      onTriggerError();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxSize: maxSize,
    onDrop,
  });

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <IconAttachment />
    </div>
  );
}
export default DropZone;
