import React, { useState, useEffect } from 'react';
import { ListingCard, IconNextArrow, IconPrevArrow, SkeletonLoader } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import PropTypes from 'prop-types';
import ItemsCarousel from 'react-items-carousel';
import classNames from 'classnames';
import SingleLoader from './SingleLoader';

import css from './ListSlider.module.css';

const LARGE_WIDTH = 1921;
const Md_LARGE_WIDTH = 1366;
const MAX_IMAGE_LAYOUT_SCREEN_WIDTH = 1265;
const TAB_WIDTH = 767;
const MOBILE_WIDTH = 767;
const MAX_IMAGE_MOBILE_SCREEN_WIDTH = 767;
const XSMOBILE_WIDTH = 480;

const ListSlider = props => {
  const next = () => {
    const activeIndex = activeItemIndex;
    const total = props.listings.length;
    if (activeIndex == total) {
      return;
    } else {
      setActiveSlide(activeIndex + 1);
    }
  };

  const previous = () => {
    const activeIndex = activeItemIndex;
    const slideToShow = slidesToShow;
    const total = props.listings.length;
    if (activeIndex == 0) {
      return;
    } else if (activeIndex > total - slideToShow + 1) {
      setActiveSlide(total - slideToShow - 1);
    } else {
      setActiveSlide(activeIndex - 1);
    }
  };

  const setActiveSlide = index => {
    const total = props.listings.length;
    if (index >= 0 && index < total) {
      setActiveItemIndex(index);
    }
  };

  const {
    title,
    listings = [],
    queryError,
    intl,
    viewport,
    isListingPageSlider,
    isOwnListing = false,
    name,
  } = props;

  let isImageLargeLayout = viewport.width > 0 && viewport.width < LARGE_WIDTH;
  let isImageMdLargeLayout =
    viewport.width > MAX_IMAGE_LAYOUT_SCREEN_WIDTH && viewport.width < Md_LARGE_WIDTH;
  let isImageTabLayout =
    viewport.width >= TAB_WIDTH && viewport.width <= MAX_IMAGE_LAYOUT_SCREEN_WIDTH;
  let isImageMMobileLayout = viewport.width > 0 && viewport.width <= MOBILE_WIDTH;
  let isImageXSMobileLayout = viewport.width > 0 && viewport.width < XSMOBILE_WIDTH;
  let isMobileLayout = viewport.width > 0 && viewport.width <= MAX_IMAGE_MOBILE_SCREEN_WIDTH;

  const isImageMobileLayout = viewport.width > 0 && viewport.width < TAB_WIDTH;
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const [slidesToShow, setslidesToShow] = useState(
    isImageXSMobileLayout
      ? 1
      : isImageMMobileLayout
      ? 2
      : isImageTabLayout
      ? 3
      : isImageMdLargeLayout
      ? 4
      : isImageLargeLayout
      ? 4
      : 4
  );

  useEffect(() => {
    isImageLargeLayout = viewport.width > 0 && viewport.width < LARGE_WIDTH;
    isImageMdLargeLayout =
      viewport.width > MAX_IMAGE_LAYOUT_SCREEN_WIDTH && viewport.width < Md_LARGE_WIDTH;
    isImageTabLayout =
      viewport.width > TAB_WIDTH && viewport.width <= MAX_IMAGE_LAYOUT_SCREEN_WIDTH;
    isImageMMobileLayout = viewport.width > 0 && viewport.width < MOBILE_WIDTH;
    isImageXSMobileLayout = viewport.width > 0 && viewport.width < XSMOBILE_WIDTH;
    setslidesToShow(
      isImageXSMobileLayout
        ? 1
        : isImageMMobileLayout
        ? 2
        : isImageTabLayout
        ? 3
        : isImageMdLargeLayout
        ? 4
        : isImageLargeLayout
        ? 4
        : 4
    );
  }, [viewport.width]);

  const slideChange = event => {
    if (isImageTabLayout) {
      setActiveItemIndex(event);
    }
  };

  const queryErrorMessage = queryError ? (
    <h2 className={css.error}>
      <FormattedMessage id="ListSlider.queryError" />
    </h2>
  ) : null;

  const noResults =
    listings.length === 0 ? (
      <div style={{ display: 'flex', width: '100%', marginBottom: '60px' }}>
        {Array(slidesToShow)
          .fill('')
          .map((item, i) => (
            <SkeletonLoader
              viewport={viewport}
              isMobile={isImageMobileLayout}
              noOfSlides={slidesToShow}
              key={i}
            />
          ))}
      </div>
    ) : null;

  const sliderButtons = (
    <div className={css.sliderButtons}>
      <span className={css.iconHolder} onClick={previous}>
        <IconPrevArrow className={css.icon} />
      </span>
      <span className={css.iconHolder} onClick={next}>
        <IconNextArrow className={css.icon} />
      </span>
    </div>
  );

  return (
    <div
      className={classNames(
        css.moreServices,
        props.moreServicesMobPadd,
        css.fullWidth,
        isListingPageSlider ? css.listingPageSlider : null
      )}
    >
      <div
        className={classNames(
          props.moreServicesMobPadd,
          css.moreServicesTitle,
          props.sliderHeading
        )}
      >
        {listings.length === 0 ? (
          <SingleLoader />
        ) : (
          <h2 className={css.sectionTitle}>
            <FormattedMessage id={title} /> <span className={css.titleHighlight}>{name}</span>
          </h2>
        )}
        {sliderButtons}
      </div>

      {/* {loadingResults} */}
      {queryErrorMessage}
      {noResults}
      {isMobileLayout ? (
        <div className={classNames(props.moreServicesMobPadd, css.cardSlider)}>
          {Array.isArray(listings) && listings.length
            ? listings.map((listing, i) => (
                <div className={isMobileLayout ? css.homepage : css.tabletHomepage} key={i}>
                  <ListingCard
                    key={listing.id.uuid}
                    listing={listing}
                    intl={intl}
                    profileSliderCardTitle={props.profileSliderCardTitle}
                    profileSliderCardauthorInfo={props.profileSliderCardauthorInfo}
                    profileSliderCardprice={props.profileSliderCardprice}
                    homeMobileSlider={isMobileLayout ? props.homeMobileSlider : ' '}
                    isOwnListing={isOwnListing}
                  />
                </div>
              ))
            : null}
        </div>
      ) : (
        <div className={css.cardSlide}>
          {Array.isArray(listings) && listings.length ? (
            <ItemsCarousel
              infiniteLoop={false}
              gutter={15}
              disableSwipe={false}
              numberOfCards={slidesToShow}
              noOfRows={2}
              slidesToScroll={1}
              showSlither={false}
              firstAndLastGutter={false}
              requestToChangeActive={slideChange}
              activeItemIndex={activeItemIndex}
            >
              {Array.isArray(listings) &&
                listings.map((listing, i) => (
                  <div className={isMobileLayout ? css.homepage : css.tabletHomepage} key={i}>
                    <ListingCard
                      key={listing.id.uuid}
                      listing={listing}
                      intl={intl}
                      profileSliderCardTitle={props.profileSliderCardTitle}
                      profileSliderCardauthorInfo={props.profileSliderCardauthorInfo}
                      profileSliderCardprice={props.profileSliderCardprice}
                      profileSliderCardWidth={props.profileSliderCardWidth}
                      isOwnListing={isOwnListing}
                    />
                  </div>
                ))}
            </ItemsCarousel>
          ) : null}
        </div>
      )}
    </div>
  );
};

ListSlider.defaultProps = {
  title: null,
  listings: [],
  queryError: null,
};

const { bool, string } = PropTypes;

ListSlider.propTypes = {
  title: string.isRequired,
};
export default ListSlider;
