import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect } from '../../components';

import css from './StripePaymentAddress.module.css';

const StripePaymentAddress = props => {
  const { className, intl, disabled, form, fieldId, card } = props;

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const stateLabel = intl.formatMessage(
    { id: 'StripePaymentAddress.stateLabel' },
    { optionalText: optionalText }
  );
  const statePlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.statePlaceholder' });

  const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.countryRequired',
    })
  );

  const handleOnChange = event => {
    const value = event.target.value;
    form.change('postal', value);
    card.update({ value: { postalCode: value } });
  };

  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);

  return (
    <div className={className ? className : css.root}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.addressLine1`}
          name="addressLine1"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line1"
          label={addressLine1Label}
          placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
          onUnmount={() => form.change('addressLine1', undefined)}
        />

        <FieldTextInput
          id={`${fieldId}.addressLine2`}
          name="addressLine2"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={addressLine2Label}
          placeholder={addressLine2Placeholder}
          onUnmount={() => form.change('addressLine2', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.postalCode`}
          name="postal"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing postal-code"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
          onUnmount={() => form.change('postal', undefined)}
          onChange={event => handleOnChange(event)}
        />

        <FieldTextInput
          id={`${fieldId}.city`}
          name="city"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          placeholder={cityPlaceholder}
          validate={cityRequired}
          onUnmount={() => form.change('city', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldSelect
          id={`${fieldId}.state`}
          name="state"
          disabled={disabled}
          className={css.field}
          label={stateLabel}
        >
          <option disabled value="">
            {statePlaceholder}
          </option>
          <option value="AL" key="AL">
            Alabama
          </option>
          <option value="AK" key="AK">
            Alaska
          </option>
          <option value="AS" key="AS">
            American Samoa
          </option>
          <option value="AZ" key="AZ">
            Arizona
          </option>
          <option value="AR" key="AR">
            Arkansas
          </option>
          <option value="CA" key="CA">
            California
          </option>
          <option value="CO" key="CO">
            Colorado
          </option>
          <option value="CT" key="CT">
            Connecticut
          </option>
          <option value="DE" key="DE">
            Delaware
          </option>
          <option value="DC" key="DC">
            District Of Columbia
          </option>
          <option value="FM" key="FM">
            Federated States Of Micronesia
          </option>
          <option value="FL" key="FL">
            Florida
          </option>
          <option value="GA" key="GA">
            Georgia
          </option>
          <option value="GU" key="GU">
            Guam
          </option>
          <option value="HI" key="HI">
            Hawaii
          </option>
          <option value="ID" key="ID">
            Idaho
          </option>
          <option value="IL" key="IL">
            Illinois
          </option>
          <option value="IN" key="IN">
            Indiana
          </option>
          <option value="IA" key="IA">
            Iowa
          </option>
          <option value="KS" key="KS">
            Kansas
          </option>
          <option value="KY" key="KY">
            Kentucky
          </option>
          <option value="LA" key="LA">
            Louisiana
          </option>
          <option value="ME" key="ME">
            Maine
          </option>
          <option value="MH" key="MH">
            Marshall Islands
          </option>
          <option value="MD" key="MD">
            Maryland
          </option>
          <option value="MA" key="MA">
            Massachusetts
          </option>
          <option value="MI" key="MI">
            Michigan
          </option>
          <option value="MN" key="MN">
            Minnesota
          </option>
          <option value="MS" key="MS">
            Mississippi
          </option>
          <option value="MO" key="MO">
            Missouri
          </option>
          <option value="MT" key="MT">
            Montana
          </option>
          <option value="NE" key="NE">
            Nebraska
          </option>
          <option value="NV" key="NV">
            Nevada
          </option>
          <option value="NH" key="NH">
            New Hampshire
          </option>
          <option value="NJ" key="NJ">
            New Jersey
          </option>
          <option value="NM" key="NM">
            New Mexico
          </option>
          <option value="NY" key="NY">
            New York
          </option>
          <option value="NC" key="NC">
            North Carolina
          </option>
          <option value="ND" key="ND">
            North Dakota
          </option>
          <option value="MP" key="MP">
            Northern Mariana Islands
          </option>
          <option value="OH" key="OH">
            Ohio
          </option>
          <option value="OK" key="OK">
            Oklahoma
          </option>
          <option value="OR" key="OR">
            Oregon
          </option>
          <option value="PW" key="PW">
            Palau
          </option>
          <option value="PA" key="PA">
            Pennsylvania
          </option>
          <option value="PR" key="PR">
            Puerto Rico
          </option>
          <option value="RI" key="RI">
            Rhode Island
          </option>
          <option value="SC" key="SC">
            South Carolina
          </option>
          <option value="SD" key="SD">
            South Dakota
          </option>
          <option value="TN" key="TN">
            Tennessee
          </option>
          <option value="TX" key="TX">
            Texas
          </option>
          <option value="UT" key="UT">
            Utah
          </option>
          <option value="VT" key="VT">
            Vermont
          </option>
          <option value="VI" key="VI">
            Virgin Islands
          </option>
          <option value="VA" key="VA">
            Virginia
          </option>
          <option value="WA" key="WA">
            Washington
          </option>
          <option value="WV" key="WV">
            West Virginia
          </option>
          <option value="WI" key="WI">
            Wisconsin
          </option>
          <option value="WY" key="WY">
            Wyoming
          </option>
        </FieldSelect>

        {/* <FieldTextInput
          id={`${fieldId}.state`}
          name="state"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level1"
          label={stateLabel}
          placeholder={statePlaceholder}
          onUnmount={() => form.change('state', undefined)}
        /> */}

        <FieldSelect
          id={`${fieldId}.country`}
          name="country"
          disabled={disabled}
          className={css.field}
          label={countryLabel}
          validate={countryRequired}
        >
          <option disabled value="">
            {countryPlaceholder}
          </option>
          {countryCodes.map(country => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </FieldSelect>
      </div>
    </div>
  );
};
StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
