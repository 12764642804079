import React from 'react';

function HomeIconMobile() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 123 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="61.5" cy="61.5" r="59.5" stroke="black" strokeWidth="4" fill="none" />
      <line x1="27.4142" y1="25.5858" x2="59.4142" y2="57.5858" stroke="black" strokeWidth="4" />
      <line x1="65.4142" y1="65.5858" x2="97.4142" y2="97.5858" stroke="black" strokeWidth="4" />
      <path d="M97.5 25.5L65.5 57.5" stroke="black" strokeWidth="4" />
      <line
        y1="-2"
        x2="45.2548"
        y2="-2"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 61 65)"
        stroke="black"
        strokeWidth="4"
      />
      <line x1="49.4142" y1="63.4142" x2="27.4142" y2="85.4142" stroke="black" strokeWidth="4" />
      <line x1="87.4142" y1="26.4142" x2="65.4142" y2="48.4142" stroke="black" strokeWidth="4" />
      <line x1="59.4142" y1="73.4142" x2="37.4142" y2="95.4142" stroke="black" strokeWidth="4" />
      <line x1="94.4142" y1="37.4142" x2="72.4142" y2="59.4142" stroke="black" strokeWidth="4" />
      <line
        y1="-2"
        x2="31.1127"
        y2="-2"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 65 72)"
        stroke="black"
        strokeWidth="4"
      />
      <line
        y1="-2"
        x2="31.1127"
        y2="-2"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 29 35)"
        stroke="black"
        strokeWidth="4"
      />
      <line
        y1="-2"
        x2="31.1127"
        y2="-2"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 74 63)"
        stroke="black"
        strokeWidth="4"
      />
      <line
        y1="-2"
        x2="31.1127"
        y2="-2"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 40 24)"
        stroke="black"
        strokeWidth="4"
      />
    </svg>
  );
}

export default HomeIconMobile;
