export const profileFilter = {
  SHIPPING: 'shipping',
  FREE_SHIPPING: 'free shipping',
  NEW: 'new',
  CATEGORY: 'category',
  SHOES: 'shoes',
  DANCEWEAR: 'dancewear',
  BRAND: 'brand',
  SIZE: 'size',
  TYPE: 'type',
  COLOR: 'color',
  CONDITION: 'condition',
  WIDTH: 'width',
  SHANK: 'shank',
  GENRE: 'genre',
  COSTUMES: 'costumes', // This is used in costume search duck and marketplace config
};

export const STATUSES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const GOOGLE_TAGS = {
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  ORDER_COMPLETE: 'order_complete',
};

export const TRANSACTION_PROCESSES = {
  OFFER_PROCESS: 'offer-process',
  RENTAL_PROCESS: 'rental-process',
};

export const SHIPPO_TRACKING_STATUSES = {
  PRE_TRANSIT: 'information_received',
  PACKAGE_ACCEPTED: 'package_accepted',
  PACKAGE_ARRIVED: 'package_arrived',
  PACKAGE_DEPARTED: 'package_departed',
  PACKAGE_PROCESSING: 'package_processing',
  OUT_FOR_DELIVERY: 'outfordelivery',
  DELIVERED: 'delivered',
  UNKNOWN: 'other',
  DELAYED: 'delayed',
  CONTACT_CARRIER: 'contact_carrier',
};

export const PARDA = {
  PAYPAL_MERCHANT_ID: 'paypal-merchant-id',
};

export const SEARCH_FILTER_COLOR_MAPPING = {
  Black: ['Black'],
  Blue: ['Blue', 'Periwinkle', 'Teal', 'Turquoise'],
  Brown: ['Bronze', 'Brown', 'Tan', 'Beige', 'Sand'],
  Gold: ['Gold'],
  Gray: ['Gray'],
  Green: ['Green', 'Mint', 'Turqoise', 'Teal'],
  'Multi-Colored': ['Print', 'Pattern', 'Multi-Colored', 'Floral'],
  Orange: ['Orange', 'Coral', 'Peach'],
  Pink: ['Pink', 'Theatrical Pink', 'Ballet Pink', 'Coral', 'Peach', 'Classical Pink'],
  Purple: ['Purple', 'Burgundy', 'Periwinkle'],
  Red: ['Red', 'Burgundy', 'Coral'],
  Silver: ['Silver'],
  SkinTones: ['Bronze', 'SkinTones', 'Tan', 'Beige', 'Sand', 'Nude', 'Cream'],
  White: ['White'],
  Yellow: ['Yellow', 'Cream'],
};
